.detailPage--Container {
  /* border: 1px solid #E2E2E1; */
  min-height: 450px !important;
}

.pg-height {
  border: 1px solid #e2e2e1;
  min-height: 789px;
}

@media screen and (max-width: 550px) {
  .pg-height {
    min-height: 500px;
  }

  .detailPage--Container {
    min-height: 250px !important;
  }
}

.availabilityContainer {
  border-radius: 12px;
  background: rgba(240, 68, 56, 0.1);
  padding: 20px 10px;
}

.noSlot-container>h6 {
  color: #101828;
  font-family: "Segoe UI";
  font-size: 19.688px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 121.905% */
}

.availabilityContainer>p {
  color: #f04438;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.noSlot-container>button {
  border-radius: 10px;
  background: #73589b;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  padding: 10px 30px;
  border: none;
  margin: auto;
  width: 100%;
}

.time-availability-container {
  scrollbar-width: thin;
  overflow-x: auto;
}

/* For WebKit (Chrome, Safari, Edge) */
.time-availability-container::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.time-availability-container::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* For WebKit (Chrome, Safari, Edge) */
.time-availability-container::-webkit-scrollbar-thumb {
  width: 0px;
  background: #888 !important;
}

.slot-availability-container {
  scrollbar-width: thin;
  overflow-x: auto;
}

/* For WebKit (Chrome, Safari, Edge) */
.slot-availability-container::-webkit-scrollbar {
  width: 2px;
  /* Adjust the width as needed */
}

/* For WebKit (Chrome, Safari, Edge) */
.slot-availability-container::-webkit-scrollbar-thumb {
  width: 0px;
  /* Adjust the width as needed */
}

.tab-content {
  border: 1px solid #E2E2E1;
  border-radius: 0.5rem;
  border-top-left-radius: 0 !important;
}