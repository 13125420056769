.modules-section {
  /* width: 80vw; */
  padding: 5%;
  height: max-content;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.modules-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.modules-container {
  position: relative;
  width: 100%;
  height: max-content;
  border-radius: 1.25rem;
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
  padding: 2.5rem;
  /* margin: 4rem 7rem 4rem 10rem; */
  /* z-index: 10; */
  /* position: absolute; */
}

.modules-heading {
  width: 100%;
  color: #343434;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem; /* 200% */
  /* display: flex; */
  letter-spacing: 0.025rem;
}

.modules-sub-heading {
  /* height: 2rem;
  color: #000; */
  font-family: "Roboto", sans-serif;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 67.727% */
  margin-top: 1rem;
  /* border-bottom: 0.0625rem solid #e0e0e0; */
}

/* .line {
  width: 62.4375rem;
  height: 0.0625rem;
  background: #e0e0e0;
  margin-top: 5rem;
} */

.modules-heading-span {
  color: var(--main);
  text-decoration-line: underline;
}

.modules-sections-heading {
  margin: 2rem 0;
  color: #6d6d6d;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.43919rem; /* 127.928% */
  letter-spacing: 0.03125rem;
}

.modules-sections-item {
  cursor: pointer;
  display: flex;
  padding: 1.75rem 2.25rem;
  margin: 1.25rem 0;
  justify-content: space-between;
  gap: 0.625rem;
  border-radius: 0.4375rem;
  background: #fff;
  color: #000;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
}

.modules-sections-item:hover {
  border-radius: 0.4375rem;
  background: linear-gradient(90deg, #73589b 0.09%, #8456c9 99.93%);

  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
  color: #fff;
}

.modules-sections-item:hover .modules-icons,
.modules-sections-item:hover .modules-text,
.modules-sections-item:hover .modules-bookmark {
  color: #fff;
}

.items {
  display: flex;
  gap: 0.625rem;
}

.modules-icons,
.modules-bookmark {
  width: 1.5rem;
  height: 1.5rem;
  color: #000000;
}

.modules-bookmark {
  justify-content: space-between;
}

.modules-text {
  width: 100%;
  color: #333;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 127.928% */
  letter-spacing: 0.03125rem;
  margin-top: 0.1rem;
  transition: color 0.3s;
}

/* .modules-text:hover {
  color: #fff;
} */

.modules-btn {
  display: flex;
  /* height: 2rem; */
  padding: 0.625rem 0.25rem;
  justify-content: flex-end;
  align-items: right;
  justify-content: space-between;
  cursor: pointer;
}

.modules-btn-text {
  color: #333;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: right;
}

.arrow {
  width: 2.1875rem;
  height: 2.1875rem;
  margin-top: -0.1rem;
  border-radius: 100%;
  margin-top: -0.5rem;
  box-shadow: 0px 2.5px 8.125px 0px #00000030;
}

.arrow-img {
  width: 0.9375rem;
  height: 0.9375rem;
}
.module-completed-icon {
  min-width: 22px;
  min-height: 22px;
  width: 22px;
  height: 22px;
}

@media (max-width: 500px) {
  .modules-sections-item {
    padding: 28px 15px;
    /* box-shadow: none;
    margin: 0; */
  }
  .modules-sidebar {
    display: none;
  }
  .modules-section {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .modules-container {
    width: 97%;
    height: max-content;
    /* margin: 5rem auto; */
    padding: 2.5rem 16px;
  }
  /* .modules-heading {
    width: 90%;
  } */
  .modules-sub-heading {
    width: 100%;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 67.727% */
    margin-top: 1rem;
  }
}
