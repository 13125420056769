.deleteAccount--section {
  background-color: #dddddd;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.deleteAccount--section .deleteAccount-container {
  border: 1px solid #cbcbcb;
  border-radius: 25px;
  background: #ffffff;
}
.deleteAccount--section .deleteAccount-container button {
  background-color: var(--primary-color);
}/*# sourceMappingURL=DeleteAccount.css.map */