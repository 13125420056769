.quiz {
  display: flex;
  overflow-x: hidden;
}

.quiz-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
}

.quiz-section {
  position: relative;
  padding: 50px;
  width: 100%;
}

.quiz-sections-name {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: calc(1.325rem + .9vw);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.quiz-info-icon {
  width: 1.5rem;
  /* height: 1.5rem; */
  /* margin-top: 0.5rem; */
  cursor: pointer;
  margin: auto;
}

.quiz-container {
  width: 100%;
  height: max-content;
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
  padding: 3rem;
  z-index: 1;
  margin: auto;
}

.quiz-save {
  display: flex;
  align-items: center;
  /* gap: 0.75rem; */
  cursor: pointer;
}

.quiz-bookmark,
.quiz-next-arrow,
.correct-ans,
.wrong-ans,
.quiz-btn-icons {
  width: 1.5rem;
  height: 1.5rem;
}

.quiz-save-text {
  width: 3.3125rem;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: 0.0225rem;
}

.quiz-section-desc {
  width: 100%;
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: 0.02813rem;
  margin-left: -2rem;
}

.quiz-question-numbers {
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: 0.02813rem;
  margin: 1rem 0;
}

.quiz-question-container {
  width: 35.2rem;
  height: max-content;
  border-radius: 0.72rem;
  background: #f4f0ff;
  margin: auto;
}

.quiz-que {
  display: flex;
  gap: 0.5rem;
  /* width: 100%; */
  height: max-content;
  justify-content: left;
  color: #000 !important;
  font-family: "Roboto", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.9255rem;
  padding: 1rem;
  /* margin-left: 2rem; */
}

.quiz-btns {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 2.5rem 2rem;
}

.quiz-true-btn,
.quiz-false-btn {
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 31.58975rem;
  height: 3.24925rem;
  padding: 0.9rem 0rem 0.94919rem 0rem;
  border-radius: 6.01706rem;
  margin-bottom: 1rem;
  color: #fff;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.44413rem;
  border: none;
}

.quiz-explanation {
  width: 31.75731rem;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.575rem */
  text-align: left;
  margin: -1rem 0 1rem 2rem;
}

.quiz-next-btn {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  width: 15rem;
  height: 2.3rem;
  margin: auto;
  padding-top: 1rem;
  padding-bottom: 1.75rem;
  justify-content: center;
  color: #6f4e9f;
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.9255rem; /* 140.034% */
  /* cursor: pointer; */
  border: none;
  background-color: transparent;
}

.quiz-footer-btns {
  display: flex;
  justify-content: space-between;

  margin-top: 2.5rem;
  cursor: pointer;
}

@media (max-width: 500px) {
  .quiz {
    overflow-x: hidden;
  }
  .quiz-section {
    /* height: 50rem; */
    margin: 0;
    padding: 0;
  }
  .quiz-sidebar,
  .quiz-bg-img,
  .quiz-save-text {
    display: none;
  }
  .quiz-container {
    width: 100%;
    height: max-content;
    border-radius: 1.25rem;
    background: #fff;
    padding: 1rem 1rem 70px 1rem;
  }
  .quiz-section-desc {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    line-height: 140%; /* 1.6875rem */
    letter-spacing: 0.0175rem;
    margin: 1rem 0;
  }
  .quiz-question-numbers {
    font-size: 1rem;
    margin: 0;
  }
  .quiz-question-container {
    width: 100%;
    height: max-content;
    border-radius: 0.72rem;
    background: #f4f0ff;
    margin-left: 0;
  }
  .quiz-que {
    width: 100%;
    height: max-content;
    justify-content: left;
    line-height: 1.20344rem;
    font-size: 1rem;
    padding: 1rem;
    margin-left: 0.5rem;
  }
  .quiz-btns {
    margin: 2.5rem 1rem;
  }
  .quiz-true-btn,
  .quiz-false-btn {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 0.5625rem 0rem 0.59325rem 0rem;
    gap: 5px;
    font-size: 1rem;
    line-height: 150%; /* 105.026% */
  }
  .quiz-explanation {
    width: 100%;
    font-size: 1rem;
    margin: 0;
    padding: 16px;
  }
  .quiz-next-btn {
    gap: 0.4rem;
    width: 15rem;
    height: 2.3rem;
    margin: 1.2rem auto 0 auto;
    padding-bottom: 1.75rem;
    font-size: 1rem;
    line-height: 1.20344rem; /* 140.034% */
  }
  .quiz-bookmark,
  .quiz-next-arrow,
  .correct-ans,
  .wrong-ans {
    width: 1.2rem;
    height: 1.2rem;
    min-width: 1.2rem;
    min-height: 1.2rem;
  }
  .quiz-footer-btns {
    gap: 3rem;
  }
}
