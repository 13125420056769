.CourseListing--Card {
  max-height: 462px;
}
@media only screen and (max-width: 500px) {
  .CourseListing--Card {
    max-height: -moz-fit-content;
    max-height: fit-content;
  }
}
.CourseListing--Card .CourseListing--Card__Title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}/*# sourceMappingURL=CourseCard.css.map */