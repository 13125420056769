.expect-section {
  width: 100%;
  max-height: 100%;
  display: flex;
  gap: 50px;
}

.expect-header {
  width: 100%;
  height: 5.5rem;
  box-shadow: 0px 0px 13px 0px #0000001a;
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  scroll-behavior: smooth;
  animation: fadeIn 3s ease-in-out forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.expect-header a {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #191c1e;
  text-decoration: none;
  position: relative;
}

.expect-header a:hover {
  color: rgb(115, 88, 155);
}

/* underline animation */
.expect-header a::before {
  content: "";
  position: absolute;
  bottom: -5px;
  height: 3px;
  width: 0;
  border-radius: 50px;
  background-color: rgb(115, 88, 155);
  transition: width 1s ease-in-out;
}

.expect-header a:hover::before {
  width: 100%;
}

.expect-main {
  display: flex;
  width: 50%;
  flex-direction: column;
  padding: 3rem 0 3rem 10rem;
  animation: slideUp 2s ease-in-out forwards;
  /* margin-bottom: 5rem; */
}

@keyframes slideUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.expect-left {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.expect-left-text {
  width: 100%;
  
  padding-bottom: 2rem;
  font-family: "Roboto", sans-serif;
}

.expect-left-heading {
  width: 100%;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 1rem 0;
}

.expect-left-para {
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.75rem;
  justify-content: left;
}

.expect-left-blueSection {
  background-image: url("/public/assets/img/course_new/course-rectangle-desktop.png");
  width: 100%;
  height: auto;
  border-radius: 1.1rem;
  height: max-content;
  padding: 1.2rem;
  font-family: "Roboto", sans-serif;
  background-repeat: no-repeat;
  background-size: cover;
}

.expect-blue-heading {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2rem;
}

.expect-blue-list {
  list-style: none;
  margin-left: -2rem;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.check-list {
  width: 16px;
  height: 16px;
  margin-top: 5px;
}

.expect-blue-list-items {
  display: flex;
  gap: 1rem;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.6rem;
}

/* .expect-right {
  padding: 5rem;
  margin-left: 5rem;
} */

.expect-right-img {
  width: 38rem;
  height: 28rem;
  margin-top: 8rem;

  /* max-width: 100%; */
}

@media (max-width: 500px) {
  .check-list {
    margin-top: -5px;
  }
  .expect-header {
    width: 100%;
    height: 5.5rem;
    gap: 6px;
    padding: 0 10px;
  }
  .expect-header a {
    font-size: 0.8rem;
    line-height: 1.2rem;
    color: #191c1e;
    text-decoration: none;
    position: relative;
    text-align: center;
  }
  .line {
    width: 0.0625rem;
    height: 1.75rem;
    flex-shrink: 0;
    opacity: 0.4;
    background: #b0c2c2;
  }
  .expect-section {
    flex-direction: column;
    max-height: 100%;
  }
  .expect-main {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 1.5rem;
    animation: moveUp 3s ease-in forwards;
    /* margin-bottom: 5rem; */
  }
  .expect-left-heading {
    text-align: center;
    width: 100%;
    margin-left: 0;
  }
  .expect-left-para {
    width: 100%;
    font-size: 1rem;
    margin: 0;
    text-align: justify;
    line-height: 1.5rem;
  }
  .expect-left-blueSection {
    background-image: url("/public/assets/img/course_new/course-rectangle-mobile.png");
    width: 100%;
    margin: 10px 0;
    border-radius: 0vw;
    overflow: hidden;
    height: max-content;
  }
  .expect-blue-list-items {
    font-weight: 500;
    text-align: left;
    font-size: 1rem;
    line-height: 130%;
    width: 100%;
    align-items: center;
  }
  .expect-right-img {
    width: 92%;
    height: auto;
    margin: 20px auto;
    display: flex;
    object-fit: contain;
  }
  .expect-left {
    width: 100%;
  }
  .expect-left-text {
    width: 100%;
    padding: 0;
  }
}

/* module section */
.module-section {
  width: 100%;
  /* height: 80vh;
  max-height: 80vh; */
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.module-heading {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.2rem;
  text-align: center;
  /* padding: 1rem 0; */
  margin: 3rem 0 -2rem 10rem;
}

.card-slider {
  /* width: 60vw;
  height: 60vh; */

  display: flex;
  margin: 1rem auto;
  /* gap: 2rem; */
  /* width: 20rem; */
}

.module-container {
  display: flex;
  flex-direction: column;
}

.module-card-section {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  margin-top: 1rem;
  justify-content: center;
}

.module-card-image {
  margin-left: 5rem;
  /* background-size: cover;
  background-position: center; */
}

.module-card-content {
  /* display: none; */
  width: 40%;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  z-index: 1;
}

.module-card-title {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.1rem;
}

.module-card-desc {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.6rem;
  text-align: left;
}

.module-arrow {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (max-width: 500px) {
  /* .module-section {
    margin-top: 5rem;
    
  } */
  .module-heading {
    font-size: 1.75rem;
    line-height: 110%;
    padding: 1rem 0;
    margin: 0;
    width: 100%;
  }
  .card-slider {
    margin: 0;
    width: 100vw;
    height: 100%;
    max-height: max-content;
  }
  .module-card-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    padding: 0;
    gap: 2rem;
    padding-bottom: 100px;
  }

  .module-cards {
    width: 100vw;
    max-height: max-content;
    /* max-height: 70vh; */
    display: flex;
    margin-top: 0;
    /* gap: 2rem; */
    /* width: 20rem; */
  }
  .module-card-content {
    /* display: none; */
    max-height: max-content;
    width: 100%;
    padding: 0 1rem;
    margin: 0;
    gap: 1rem;
    text-align: left;
  }

  .module-card-title {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 120%;
  }
  .module-card-desc {
    max-height: 40rem;
    justify-items: center;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 130%;
  }
  .module-card-image {
    width: 100%;
    height: max-content;
    left: 0;
    margin-left: 0;
  }
  .module-card-image img {
    display: flex;
    margin: auto;
  }
}

/* course-section */
.course-section {
  width: 100%;
  /* max-width: 1920px;
  margin: auto; */
  max-height: max-content;
  min-height: 60vh;
  display: flex;
  flex-direction: row;
  padding: 5rem 12rem;
  justify-content: center;
  gap: 20px;
  background: linear-gradient(
    97.71deg,
    #f5f1ff -19.86%,
    rgba(233, 224, 255, 0) 110.5%
  );
}

.course-container {
  margin-left: 4rem;
  width: 31rem;
  height: 100%;
  /* margin-left: 5rem; */
}

.course-heading {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 3rem;
  line-height: 4.5rem;
  letter-spacing: 3%;
}

.course-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
  padding: 2rem 0;
}

.course-list-items {
  display: flex;
  gap: 2rem;
  cursor: pointer;
}

.course-list-check {
  border: 5px black;
  border-radius: 100%;
  width: 2.1rem;
  height: 2.1rem;
  background-color: transparent;
}

.course-list-img {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.course-list-heading {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.5%;
  margin-top: 10px;
  margin-bottom: 0 !important;
}

.course-list-para {
  padding: 0.3rem 0;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
  letter-spacing: 0.75%;
  max-height: 0;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
}

.course-list-para.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.6s ease-in-out;
}

.course-btn {
  width: 18rem;
  height: 3.25rem;
  margin-top: 3rem;
  /* margin-left: 15rem; */
  gap: 1.5px;

  padding: 0.8rem 3.1rem;

  font-family: "Roboto", sans-serif;;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: white;
  text-align: center;
  cursor: pointer;

  border-radius: 8px;
 
  background: var(--button-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.course-btn .MuiCircularProgress-root{
  width: 20px !important;
  height: 20px !important;
}
.course-btn:disabled {
  background: #73589ba6;
}

.course-img {
  /* width: 30rem; */
  max-width: 30rem;
  height: 20rem;
  /* max-height: 20rem; */
  /* margin-left: 10rem; */
  margin-top: 2rem;
  animation: slideUp 2s ease-in-out forwards;
 
}

@keyframes slideUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 500px) {
  .course-section {
    width: 100%;
    flex-direction: column-reverse;
    padding: 0;
    margin-top: 20px;
  }
  .course-img {
    width: 100%;
    height: max-content;
    margin-left: 0;
    margin-top: 0;
    padding: 10px 16px;
  }
  .course-container {
    margin-top: 0px;
    margin-left: 0;
    padding: 20px;
    width: 100%;
  }
  .course-heading {
    font-size: 1.5rem;
    line-height: 1.69825rem;
    letter-spacing: 0.01544rem;
    margin-bottom: -2rem;
  }
  .course-list-para {
    width: 70%;
    padding: 0.3rem 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6rem;
    letter-spacing: 0.75%;
    max-height: 0;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
  }
  .course-list-items {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  .course-btn {
    margin: auto;
  }
}

/* meet your instructor */
.instructor-section {
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.instructor-container {
  width: 100%;
  display: flex;
  gap: 5rem;
}

.instructor-image {
  width: 100%;
  height: 100%;
  animation: scaleAnimation 1s ease-in-out forwards;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.instructor-data {
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  font-family: "Roboto", sans-serif;
  animation: slideUp 2s ease-in-out forwards;
}

@keyframes slideUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.instructor-heading {
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 2.5rem;
  letter-spacing: 2%;
}

.instructor-namee {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}

.instructor-name {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2rem;
  /* width: 70%; */
}

.instructor-expertise {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.1rem;
  padding: 0.2rem 0.75rem;
  text-align: center;
  background-color: #e0f6d8;
  color: #134700;
  border-radius: 1rem;
  width: fit-content;
  margin-bottom: 1rem;
}

.instructor-rating {
  display: flex;
  gap: 0.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 1.05rem;
  line-height: 1.5rem;
  letter-spacing: 0.1rem;
  align-items: center;
}

.instructor-experience,
.instructor-languages {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.6rem;
  margin: 1rem 0;
}
.instructor-languages {
  margin-top: 0;
}

.instructor-language {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.6rem;
  margin: 1rem 0;
}

.instructor-desc {
  width: 27rem;
  height: 15rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.7rem;
  text-align: left;
  margin: 0.5rem 0;
}

@media (max-width: 500px) {
  .instructor-section {
    width: 100%;
    height: max-content;
  }
  .instructor-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 20px;
  }
  .instructor-heading {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 1.75rem;
    letter-spacing: 2%;
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    /* margin-left: -1rem; */
  }
  .instructor-name {
    width: max-content;
    margin-right: 10px;
    font-size: 1.7rem;
  }
  .instructor-image {
    margin-top: 18px;
    width: 100%;
  }
  .instructor-image img {
    border-radius: 20px;
  }
  .instructor-data {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    margin-left: 0rem;
    font-family: "Roboto", sans-serif;
    animation: moveUp 3s ease-in forwards;
  }
  .instructor-desc {
    width: 100%;
    height: 100%;
    max-height: max-content;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.7rem;
    justify-content: left;
    padding-right: 0.25rem;
  }
}

/* results */
.results-section {
  width: 100%;
  height: 100%;
  background: linear-gradient(244deg, #f5f4ff -14.24%, #d9d5ff 114.63%);
  margin-bottom: 2rem;
}

.results-container {
  padding: 3rem 0rem;
}

.results-heading {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.2rem;
  margin-left: 40%;
}

.results-list {
  max-width: 1920px;
  width: 100%;
  /* height: 11rem; */
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  padding: 4rem;
  margin: 0 auto;
  justify-content: space-between;
}

.results-list-items {
  width: 85%;
  height: 10.25rem;
  margin-top: -1rem;
  opacity: 0;
  transform: translateY(75px);
  animation: slideUp 0.8s ease-in-out forwards;
}

.results-list-items:nth-child(1) {
  animation-delay: 0.1s; /* Adjust delay for the first item */
}

.results-list-items:nth-child(2) {
  animation-delay: 0.3s; /* Adjust delay for the second item */
}

.results-list-items:nth-child(3) {
  animation-delay: 0.5s; /* Adjust delay for the third item */
}

.results-list-items:nth-child(4) {
  animation-delay: 0.7s; /* Adjust delay for the fourth item */
}

/* @keyframes slideUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
} */

.results-list-heading {
  font-weight: 700;
  font-size: 3.8rem;
  line-height: 4rem;
  letter-spacing: 2%;
}

.results-list-desc {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 2rem;
  letter-spacing: 2%;
  padding: 0.5rem 0;
}

.img-star {
  width: 40px;
  height: 40px;
}

.img-google {
  width: 189px;
  height: 65px;
}

@media (max-width: 500px) {
  .results-section {
    width: 100%;
    height: 100%;
  }
  .results-container {
    padding: 26px;
  }
  .results-heading {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 3rem;
    text-align: center;
    width: 100%;
    margin-left: 0rem;
  }
  .results-list {
    padding: 36px 0 0 0;
    margin-left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    justify-content: space-between;
    animation: fadeInUp 0.5s ease-in-out forwards;
  }
  .results-list-items {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 0;
  }
  .results-list-heading {
    font-weight: 700;
    font-size: 1.9rem;
    line-height: 1.8rem;
    letter-spacing: 2%;
  }
  .results-list-desc {
    width: 100%;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 2%;
    padding: 0.5rem 0;
  }
  .img-star {
    width: 30px;
    height: 30px;
  }
  .img-google {
    width: 85px;
    height: auto;
    object-fit: contain;
  }
  .expect-blue-heading {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}
