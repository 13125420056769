.deleteAccount--section {
  background-color: #dddddd;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .deleteAccount-container {
    border: 1px solid #cbcbcb;
    border-radius: 25px;
    background: #ffffff;

    button{
        background-color: var(--primary-color);
    }
  }
}
