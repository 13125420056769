.VideoCard--module {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 1.84px 3.68px 29.44px 0px rgba(0, 0, 0, 0.08);
  padding: 1px;
}
.VideoCard--module h6 {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
  cursor: pointer;
}
.VideoCard--module .title-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.VideoCard--module p {
  color: #707070;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.VideoCard--module .clubDiv {
  border-radius: 24px;
  background: #f6f0ff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 10px;
}
.VideoCard--module .article-duration {
  position: absolute;
  top: 4%;
  right: 4%;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.66);
  -webkit-backdrop-filter: blur(41.5499992371px);
          backdrop-filter: blur(41.5499992371px);
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 10px;
}
.VideoCard--module .video-duration {
  position: absolute;
  top: 4%;
  right: 4%;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.66);
  -webkit-backdrop-filter: blur(41.5499992371px);
          backdrop-filter: blur(41.5499992371px);
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 10px;
}
.VideoCard--module .video-playBtn {
  position: absolute;
  top: 29%;
  left: 45%;
}
.VideoCard--module img {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  aspect-ratio: 16/9;
}

.Video--Popup {
  width: auto;
}

.videoModal--container .boxContainer {
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
  min-width: 600;
  min-height: 500;
  background-color: "#f5f1fe";
  box-shadow: 16;
  aspect-ratio: 16/9;
}
.videoModal--container .boxContainer iframe {
  width: 900px;
  height: 600px;
}

@media screen and (max-width: 500px) {
  .videoModal--container .boxContainer {
    aspect-ratio: 16/9;
    min-width: -moz-fit-content;
    min-width: fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
  }
  .videoModal--container .boxContainer iframe {
    width: 100vw;
    height: 100vh;
  }
}
.BlogCard--module {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 1.84px 3.68px 29.44px 0px rgba(0, 0, 0, 0.08);
  padding: 1px;
  cursor: pointer;
}
.BlogCard--module h6 {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.BlogCard--module .title-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.BlogCard--module p {
  color: #707070;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.BlogCard--module .clubDiv {
  border-radius: 24px;
  background: #f6f0ff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 10px;
}
.BlogCard--module .article-duration {
  position: absolute;
  top: 4%;
  right: 4%;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.66);
  -webkit-backdrop-filter: blur(41.5499992371px);
          backdrop-filter: blur(41.5499992371px);
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 10px;
}
.BlogCard--module .video-duration {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.66);
  -webkit-backdrop-filter: blur(41.5499992371px);
          backdrop-filter: blur(41.5499992371px);
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 10px;
}
.BlogCard--module .video-playBtn {
  position: absolute;
  top: 29%;
  left: 45%;
}
.BlogCard--module img {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  aspect-ratio: 16/9;
}

@media screen and (max-width: 500px) {
  .podcastModal--container .boxContainer {
    aspect-ratio: 16/9;
    min-width: -moz-fit-content;
    min-width: fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
  }
  .podcastModal--container .boxContainer iframe {
    width: 100vw;
    height: 100vh;
  }
}/*# sourceMappingURL=VideoCard.css.map */