.sidebar-section {
  width: 20rem;
  height: 62rem;

  box-shadow: 0px 4px 48px 0px #0000001c;
}

.sidebar-list {
  width: 20.5rem;
  height: 39.25rem;
  padding: 1rem 2rem 0 0;
}

.sidebar-li {
  text-decoration: none;
  text-decoration-line: none;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem 3rem;
  cursor: pointer;
}

.sidebar-li:hover {
  border-left: 4px solid rgba(179, 153, 253, 0.34);
  background: linear-gradient(90deg, rgba(179, 153, 253, 0.34) 0%, rgba(237, 223, 255, 0) 100%);
  animation: slide 2s ease-in-out infinite; /* Make the animation infinite if needed */
}
.sidebar--mobile {
  display: none;
}

@keyframes slide {
  to {
    left: 100%; /* Move the gradient to the right edge */
  }
}

.sidebar-li p {
  text-decoration: none;
  text-decoration-line: none;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: 0.1rem;
}

.sidebar-li img {
  width: 24px;
  height: 24px;
}

.sidebar-app {
  width: 14.375rem;
  height: 20rem;
  gap: 9px;
  align-items: center;
  justify-items: center;
  margin-top: -15rem;
  display: flex;
  flex-direction: column;
}

.sidebar-app-heading {
  margin-left: 35%;
  display: flex;
  gap: 0.5rem;
}

.sidebar-app-heading > p {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: 0.1rem;
}

.sidebar-app-qr {
  margin-left: 40%;
}

.sidebar-qr-text {
  width: 150%;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.1rem;
  text-align: center;
  margin-left: -20%;
}

@media (max-width: 450px) {
  .sidebar--mobile {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
    background: #fff;
    z-index: 1000;
  }
  .sidebar--mobile button {
    flex: 1;
    padding: 20px;
    border: none;
    background-color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.36px; /* 76.8% */
  }
  .sidebar--mobile button :first-child() {
    border-right: 1px solid #efefef !important;
  }
}

.navbar--tools .MuiDrawer-paperAnchorBottom {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.navbar--tools .navbar--tools__container > div:first-child {
  width: 81px !important;
  height: 4px !important;
  margin: 20px auto;
  background-color: #959595;
}
