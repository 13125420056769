.CourseListing--Card {
    max-height: 462px;
    @media only screen and (max-width: 500px) {
      max-height: fit-content;
    }
  .CourseListing--Card__Title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}
