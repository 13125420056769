.testimonial-section {
  width: 100%;
  /* height: 80vh; */
  padding: 0;
}

.testimonial-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* position: relative; */
}

.testimonial-heading {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 3rem;
  line-height: 2.2rem;
  text-align: center;
  margin-top: 1rem;
}

.mySwiper {
  width: 80vw;
  margin-top: 3rem;
  margin-bottom: 3rem;
  /* position: relative; */
}



.testimonial-card {
  padding: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  /* position: relative; */
  width: 50%;
}

.testimonial-card {
  /* width: 40rem;
  height: 30rem; */
  border-radius: 2.5rem;
  padding: 2rem 1rem;
  border: none;
  background-color: #ebe9fe;
  display: flex;
  gap: 3rem;
}

.testimonial-card:nth-child(2) {
  width: 400px;
  height: 450px;
  z-index: 1;
  transform: scale(1.1);
  opacity: 1;
}
.testimonial-card:first-child,
.testimonial-card:last-child {
  width: 250px;
  height: 350px;
  z-index: -1;
  transform: scale(0.9);
  opacity: 0.7;
}

.testimonials-comma {
  width: 4.5rem;
  height: 4rem;
}

.swiper-button-next,
.swiper-button-prev {
  width: 110%;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  color: rgb(115, 88, 155);
  position: absolute;
  top: 50%;
  margin-left: -2rem;
  cursor: pointer;
}

.swiper-button-next {
  margin-left: 20rem;
}

.swiper-button-prev {
  margin-left: 0rem;
}

.custom-arrow {
  width: 20px;
  height: 20px;
  color: rgb(115, 88, 155);
  color: white;
  font-weight: 500;
  border-radius: 100%;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.custom-arrow:hover {
  opacity: 1;
}

.testimonials--card .card {
  display: flex;
  flex-direction: column;
  border: none;
  background-color: #ebe9fe;
  min-height: 423px;
  justify-content: space-between;
}

.card-data {
  width: 100%;
  height: max-content;
  padding: 1rem;
  margin-top: 1.5rem;
  /* align-items: center;
  justify-content: center; */
}

.card-heading {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #252525;
}

.card-desc {
  padding: 0.75rem 0.5rem 0.75rem 0.1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  color: #525252;
  text-align: justify;
  width: 100%;
  word-break: break-word;
  margin-top: 1rem;
}

.card-user {
  display: flex;
  gap: 1.5rem;
  padding: 1rem;
  margin-left: 1.2rem;
}

.card-userImage {
  border-radius: 100%;
  width: 4rem;
  height: 4rem;
  margin-top: 2rem;
}

.card-userData {
  font-family: "Roboto", sans-serif;
  color: #424242;
  margin-top: 1.75rem;
}

.card-userName {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.7rem;
}

.card-courseName {
  font-weight: 500;
  font-size: 1.05rem;
  line-height: 1.4rem;
}

@media (max-width: 500px) {
  .testimonial-container {
    height: max-content;
  }
  .mySwiper {
    width: 100%;
    margin-top: 0;
  }
  .testimonial-heading {
    font-size: 1.8rem;
    line-height: 2.1rem;
    text-align: center;
    width: 100%;
    margin: 0;
  }
  .testimonial-cards {
    margin: 2.5rem 0 0 0;
    width: 92%;
    max-height: max-content;
    /* max-height: 38rem; */
    border-radius: 1.5rem;
    padding: 1rem;
    border: none;
    background-color: #ebe9fe;
    /* align-items: center;
    justify-content: center; */
    display: flex;
  }
  .card-data {
    width: 21rem;
    /* height: 22rem; */
    padding: 1rem 1rem;
  }
  .card-heading {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: #252525;
    /* margin-top: -1.75rem; */
  }
  .card-user {
    display: flex;
    width: 100%;
    gap: 0.6rem;
    padding: 0.8rem;
    /* margin-top: 5rem; */
  }
  .card-userData {
    font-family: "Roboto", sans-serif;
    color: #424242;
    /* padding: 2.5rem; */
    margin-top: -1rem;
  }
  .card-userName {
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.3rem;
  }
  .card-userImage {
    width: 4.5rem;
    height: 4.5rem;
    margin-top: -1rem;
  }
  .card-courseName {
    font-weight: 500;
    font-size: 1.05rem;
    line-height: 1.4rem;
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 110%;
    display: flex !important;
    justify-content: space-between;
    background-color: transparent;
    color: rgb(115, 88, 155);
    position: absolute;
    top: 45%;
    cursor: pointer;
  }
  .swiper-button-next {
    margin-left: 12rem;
  }
  .swiper-button-prev {
    margin-left: 2rem;
  }
  .custom-arrow {
    width: 20px; /* Adjust the width as needed */
    height: 20px; /* Adjust the height as needed */
    color: rgb(115, 88, 155);
    /* color: white; */
    font-weight: 500;
    border-radius: 100%;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
}
