p {
  margin-bottom: 0;
}
.ContactCard {
  //padding: 10px;
  min-width: 340px;
  border-radius: 15px;
  background-color: #fff;
  text-decoration: none;
  border: 0.75px solid #e6e6e6;
  box-shadow: 1.38px 2.76px 22.08px 0px rgba(0, 0, 0, 0.08);
  @media only screen and (max-width: 600px) {
    max-width: 270px !important;
    min-width: 270px;
  }
  .ContactCard--Content {
    display: flex;
    // gap: 10px;
  }
  .card-title {
    color: #000 !important;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .card-img-top {
    width: 120px;
    //height: 100px;
    //aspect-ratio: 1/1;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    object-fit: cover;
    @media only screen and (max-width: 600px) {
        width: 100px;
    }
  }

  .card-body {
    padding: 0;

    .ContactCard--desc {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 10px;

      & > div {
        display: flex;
        align-items: flex-start;
        gap: 3px;
        color: rgba(0, 0, 0, 0.75);
        //fill: #6a6c6a;
        p {
          font-weight: 500;
          font-size: 0.75rem;
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
.bookExpert--Btn {
  text-align: center;
  margin-top: 10px;
  padding: 8px;
  background: var(--primary-color);
  border-radius: 8px;
  justify-content: center;
  color: #fff !important;
  font-size: 0.95rem;
}
