.community--main {
  .css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
    background-color: var(--primary-color) !important;
  }
  .feed--container {
    .selection--tab {
      padding: 20px;

      .selectedTab {
        color: #73589b;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%; /* 20.8px */
        border-radius: 28px;
        background: #f7f4ff;
        cursor: pointer;
        padding: 5px 9px;
      }
      .nonSelected {
        color: #343336;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%; /* 20.8px */
        border-radius: 28px;
        background: #f7f7f7;
        cursor: pointer;
        padding: 5px 9px;
      }
    }

    @media screen and (max-width: 500px) {
      .feedDiv {
        overflow-x: scroll;

        img {
          width: 56px !important;
        }
      }
    }

    .feedDiv {
      img {
        width: 69px;
      }
    }

    .feed-container {
      p {
        color: #73589b;
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-size: 14.606px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; 
      }
    }

    hr {
      border-top: 2px solid #ececec;
      opacity: 1 !important;
      width: 75%;
      display: block;
      margin: 0;
    }
  }

  .post--rightSide {
    .rightSide-createContainer {
      display: flex;
      flex-direction: column;
      gap: 5px;
      border-radius: 19.2px;
      border: 1.2px solid #eee;
      background: #fff;
      margin: 0px 20px 20px;

      h5 {
        color: #0f1419;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.28px;
        margin: 0;
        padding: 20px;
      }

      .create {
        display: flex;
        align-items: center;
        padding: 20px;
        gap: 10px;
        cursor: pointer;

        color: #323232;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }

      .create-active {
        display: flex;
        align-items: center;
        padding: 20px;
        gap: 10px;
        cursor: pointer;

        background: linear-gradient(
          90deg,
          rgba(179, 153, 253, 0.34) 0%,
          rgba(237, 223, 255, 0) 100%
        );
        color: #73589b;
        h6 {
          color: #73589b;
          font-family: "Roboto", sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; 
        }
      }
    }

    .rightSide-followContainer {
      display: flex;
      flex-direction: column;
      gap: 5px;
      border-radius: 19.2px;
      border: 1.2px solid #eee;
      background: #fff;
      margin: 0px 20px 20px;
      padding: 20px 10px;

      hr {
        width: 100%;
      }

      h5 {
        color: #0f1419;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.28px;
        margin: 0;
      }

      .followCard {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        h6 {
          color: #0f1419;
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        p {
          color: var(--Dark-5, #5b7083);
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.306px;
        }

        .followingBtn {
          border-radius: 10798.921px;
          background: var(--primary-color);
          border: 0;
          color: #fff;
          text-align: center;
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 20.52px;
          padding: 10px;
        }

        .followBtn {
          border-radius: 10798.921px;
          border: 1.08px solid var(--primary-color);
          color: var(--primary-color);
          text-align: center;
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 20.52px;
          padding: 10px;
          background: #fff;
        }
      }
    }
  }
}

.post--container {
  display: block;
  border-top: 2px solid #ececec;

  .post--header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 500px) {
      .headerLeft {
        img {
          height: 37px !important;
          width: 37px !important;
        }

        h3 {
          font-size: 15px !important;
        }
      }
    }
    .headerLeft {
      display: flex;
      align-items: center;
      gap: 15px;

      img {
        height: 56px;
        width: 56px;
        border-radius: 50%;
      }
      h3 {
        color: #000;
        font-feature-settings: "clig" off, "liga" off;
        font-family: "Roboto", sans-serif;
        font-size: 20.289px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
      }
    }

    @media screen and (max-width: 500px) {
      .headerRight {
        div {
          border-radius: 45.76px;
          background: #f6f0ff;
          padding: 8px 8px !important;
        }

        p {
          font-size: 12px !important;
        }
      }
    }

    .headerRight {
      display: flex;
      align-items: center;
      gap: 15px;

      div {
        border-radius: 45.76px;
        background: #f6f0ff;
        padding: 5px 15px;
      }

      p {
        color: var(--primary-color);
        text-align: center;
        font-feature-settings: "clig" off, "liga" off;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .postMedia div:first-child {
      width: 98vw !important;
    }
  }

  .postBody {
    .postImage {
      height: 500px;
      object-fit: contain;
    }

    .postMedia div:first-child {
      width: 640px;
    }

    .postBody-article {
      position: relative;

      img {
        border-start-end-radius: 23px;
        border-start-start-radius: 23px;
      }
      h2 {
        color: #000;
        font-feature-settings: "clig" off, "liga" off;
        font-family: "Roboto", sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      span {
        position: absolute;
        top: 4%;
        right: 4%;
        border-radius: 190.667px;
        background: rgba(0, 0, 0, 0.76);
        backdrop-filter: blur(79.22198486328125px);
        color: #fff;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 10px;
      }
    }

    .postBody-audio {
      position: relative;

      img {
        border-radius: 23px;
      }
      h2 {
        color: #000;
        font-family: "Roboto", sans-serif;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.572px;
      }

      span {
        position: absolute;
        top: 4%;
        right: 4%;
        border-radius: 190.667px;
        background: rgba(0, 0, 0, 0.76);
        backdrop-filter: blur(79.22198486328125px);
        color: #fff;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 10px;
      }
    }

    .postBody-video {
      position: relative;

      img {
        border-start-end-radius: 23px;
        border-start-start-radius: 23px;
      }

      h2 {
        color: #000;
        font-feature-settings: "clig" off, "liga" off;
        font-family: "Roboto", sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      svg {
        position: absolute;
        top: 34%;
        left: 44%;
      }

      span {
        position: absolute;
        top: 4%;
        right: 4%;
        border-radius: 190.667px;
        background: rgba(0, 0, 0, 0.76);
        backdrop-filter: blur(79.22198486328125px);
        color: #fff;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 10px;
      }
    }
  }

  .post--footer {
    .post--footer-img {
      cursor: pointer;
    }
    h5 {
      color: #000;
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    h6 {
      color: #000;
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    a {
      color: #6e6e6e;
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .addComment-Container {
      input::placeholder {
        color: #6e6e6e;
        font-feature-settings: "clig" off, "liga" off;
        font-family: "Roboto", sans-serif;
        font-size: 18.517px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    p {
      color: #6e6e6e;
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Roboto", sans-serif;
      font-size: 15.872px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.createModal {
  .modal-content {
    background: #fff;
    border-radius: 28px;
    .createPost {
      .MuiButton-outlined {
        border-radius: 9.6px !important;
        border: 1.2px solid #d9d9d9 !important;
        background: #fff !important;
        color: #989898;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: none !important;
      }
      .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
        color: #989898 !important;
        font-family: Roboto !important;
        font-size: 16pxpx !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
      }
      .postSubmit {
        border-radius: 8px;
        background: var(--primary-color);
        display: block;
        margin: 70px auto 20px;
      }
      .captureImageDiv {
        position: relative;

        img {
          border: 1px solid #d9d9d9;
          height: 100px;
          width: 100px;
        }
      }
      .deleteFileBtn {
        background-color: #888;
        position: absolute;
        top: -12%;
        right: -12%;
        border-radius: 50%;
        height: 25px;
        width: 25px;
        color: #fff;
        padding: 5px;
        z-index: 999;
      }

      .stepTwo {
        .selectedClub {
          position: relative;

          span {
            position: absolute;
            top: 5px;
            right: 5px;
          }
        }
        .clubContainer,
        .feedTextContainer {
          border-radius: 12px;
          border: 0.3px solid #d9d9d9;
          background: #fff;
          box-shadow: 0px 4.8px 16.8px 0px rgba(0, 0, 0, 0.16);
        }

        .clubContainer {
          max-height: 520px;
          overflow-y: auto;
          .clubImage {
            height: 90px;
            width: 90px;
            border-radius: 50%;
          }
        }
        h6 {
          color: var(--primary-color);
          font-family: "Roboto", sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }

    .createVideoPost {
      .MuiButton-outlined {
        border-radius: 9.6px !important;
        border: 1.2px solid #d9d9d9 !important;
        background: #fff !important;
        color: #989898;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
        color: #989898 !important;
        font-family: Roboto !important;
        font-size: 16pxpx !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
      }
      .postSubmit {
        border-radius: 8px;
        background: var(--primary-color);
        display: block;
        margin: 70px auto 20px;
      }
    }

    .createBlog {
      .MuiButton-outlined {
        border-radius: 9.6px !important;
        border: 1.2px solid #d9d9d9 !important;
        background: #fff !important;
        color: #989898;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
        color: #989898 !important;
        font-family: Roboto !important;
        font-size: 16pxpx !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
      }
      .postSubmit {
        border-radius: 8px;
        background: var(--primary-color);
        display: block;
        margin: 70px auto 20px;
      }
    }

    .createPodcast {
      .createPodcast {
      }
      .MuiButton-outlined {
        border-radius: 9.6px !important;
        border: 1.2px solid #d9d9d9 !important;
        background: #fff !important;
        color: #989898;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
        color: #989898 !important;
        font-family: Roboto !important;
        font-size: 16pxpx !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
      }
      .postSubmit {
        border-radius: 8px;
        background: var(--primary-color);
        display: block;
        margin: 70px auto 20px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .categoryDetail-section {
    padding: 15% 0 !important;
  }
}

.categoryDetail-section {
  width: 100%;
  padding: 15% 5%;
  height: max-content;
  overflow-x: hidden;
  position: relative;

  .module-bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;

    img {
      height: 100%;
      width: 100%;
      object-fit: none;
    }
  }

  .module-container {
    position: relative;
    width: 100%;
    height: max-content;
    border-radius: 1.25rem;
    border-radius: 1.25rem;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
    // padding: 2.5rem;

    h3 {
      color: var(--primary-color);
      font-family: "Roboto", sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; 
    }

    .memberDiv {
      border-radius: 4px;
      border: 1px solid rgba(163, 162, 164, 0.34);
      color: #808080;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 5px 10px;
    }

    .categoryDiv {
      border-radius: 24px;
      background: #f6f0ff;
      padding: 7px 13px;
      width: fit-content;
      margin-bottom: 10px;

      color: #73589b;
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .containedBtn {
      border-radius: 5.6px;
      background: var(--primary-color);
      color: #fff;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .outlinedBtn {
      border-radius: 5.6px;
      border: 1.027px solid var(--primary-color);
      color: var(--primary-color);
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    p {
      color: #323232;
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; 
    }

    .suggested-clubs {
      border-radius: 17.28px;
      border: 1.08px solid #eee;
      background: #fff;
      height: max-content;

      h3 {
        color: #0f1419;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 15px 15px;
      }

      .clubs-card {
        p {
          color: #0f1419;
          font-family: "Roboto", sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        button {
          border-radius: 10798.921px;
          background: var(--primary-color);
          color: #fff;
          text-align: center;
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 20.52px; 
        }
      }
    }
  }

  .tabs-section {
    .tabs-container {
      border-radius: 64.8px;
      border: 0.88px solid var(--primary-color);
      color: var(--primary-color);
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 10px 15px;
      cursor: pointer;
    }
    .tabs-container-active {
      border-radius: 64.8px;
      background: var(--primary-color);
      color: #fff;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 10px 15px;
      cursor: pointer;
    }
  }
}

.clubCardContainer {
  .clubCard {
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 5.6px 35px 0px rgba(0, 0, 0, 0.11);
    min-height: 420px;

    img {
      height: 240px;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
    }

    .categoryName {
      position: absolute;
      top: 4%;
      left: 4%;
      border-radius: 24px;
      background: #f6f0ff;
      padding: 5px;
      color: #73589b;
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  h5 {
    color: #000;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }

  span {
    color: #707070;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }

  p {
    color: #000;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
  }

  button {
    border-radius: 16.8px;
    border: 1.4px solid #c5c5c5;
    background: var(--primary-color);
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 0;
  }
}

.detailPage-Module {
  h6 {
    color: #000;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  p {
    color: #707070;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .videoPlayer {
    margin-top: 2rem;
    width: 100% !important;
    height: auto;
    border-radius: 1rem;
  }
  .videoPlayervideo > div {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 16/9;
  }
  .videoPlayeraudio > div {
    width: 100% !important;
    aspect-ratio: 16/9;
  }

  .post--footer {
    .post--footer-img {
      cursor: pointer;
    }

    h5 {
      color: #000;
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    h6 {
      color: #000;
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    a {
      color: #6e6e6e;
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .addComment-Container {
      input::placeholder {
        color: #6e6e6e;
        font-feature-settings: "clig" off, "liga" off;
        font-family: "Roboto", sans-serif;
        font-size: 18.517px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    p {
      color: #6e6e6e;
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Roboto", sans-serif;
      font-size: 15.872px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  @media screen and (max-width: 500px) {
    .contentClubDiv {
      padding: 5px !important;

      span {
        font-family: "Inter" !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 130% !important;
        color: #0e0f0c !important;
      }
    }

    .contentActionDiv {
      font-family: "Inter" !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 130% !important;
      color: #0e0f0c !important;
      padding: 5px !important;
    }
  }

  .contentContainer {
    .contentActionDiv {
      border-radius: 78.55px;
      background: #ececec;
      color: #0e0f0c;
      font-family: "Roboto", sans-serif;
      font-size: 21.994px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; 

      display: flex;
      gap: 10px;
      align-items: center;
      padding: 10px 20px;
    }

    .contentClubDiv {
      border-radius: 50.27px;
      background: #f6f0ff;
      padding: 10px 20px;

      span {
        color: var(--primary-color);
        text-align: center;
        font-feature-settings: "clig" off, "liga" off;
        font-family: "Roboto", sans-serif;
        font-size: 20.946px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .blog-LeftSide {
    h2 {
      font-family: "Inter" !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 24px !important;
      line-height: 140% !important;
    }

    h6 {
      font-family: "Roboto" !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 130% !important;
      color: #424242 !important;
    }

    .profileImage {
      width: 32px !important;
      height: 32px !important;
    }

    .postByDetail {
      padding: 5px !important;

      h6 {
        color: #000 !important;
        font-family: "Roboto" !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 11.7629px !important;
        line-height: 14px !important;
        display: flex !important;
        align-items: center !important;
      }

      p {
        color: #000;
        font-family: Roboto;
        font-size: 21.333px;
        font-style: italic;
        font-weight: 400;
        line-height: 36px; /* 168.75% */
        letter-spacing: -0.427px;
      }
    }
  }
}

.detailPage-Blogs {
  .blog-LeftSide {
    h2 {
      color: #2e2e2e;
      font-family: "Roboto", sans-serif;
      font-size: 64px;
      font-style: normal;
      font-weight: 600;
      line-height: 76.8px; /* 120% */
    }
    h6 {
      color: #424242;
      font-family: "Roboto", sans-serif;
      font-size: 21.333px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 27.733px */
    }
    .profileImage {
      border-radius: 8px;
      height: 80px;
      width: 80px;
    }

    .postByDetail {
      border-left: 2.667px solid #000;
      padding: 5px 20px;

      h6 {
        color: #000;
        font-family: Roboto;
        font-size: 21.333px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px; /* 168.75% */
      }

      p {
        color: #000;
        font-family: Roboto;
        font-size: 21.333px;
        font-style: italic;
        font-weight: 400;
        line-height: 36px; /* 168.75% */
        letter-spacing: -0.427px;
      }
    }
  }
  .blog-RightSide {
    h3 {
      color: #000;
      font-family: "Roboto", sans-serif;
      font-size: 23.542px;
      font-style: normal;
      font-weight: 600;
      line-height: 33.333px; /* 141.593% */
      letter-spacing: 0.667px;
      text-transform: uppercase;
    }
    a {
      color: #000;
      font-family: "Roboto", sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 33.333px; /* 138.889% */
      letter-spacing: 0.667px;
      text-decoration-line: underline;
      text-transform: uppercase;
    }
  }
  .blog-relatedContainer {
    background: #f9f8ff;
    padding: 40px;

    h3 {
      color: #150c06;
      text-align: center;
      font-family: Roboto;
      font-size: 65.885px;
      font-style: italic;
      font-weight: 500;
      line-height: 73.333px; /* 111.304% */
      letter-spacing: -2.667px;
    }
  }
}

.videoListing--module {
  min-height: 310px;
  display: flex;
  flex-direction: column;

  h6 {
    color: #000;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
  }

  p {
    color: #707070;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto", sans-serif;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .clubDiv {
    border-radius: 24px;
    background: #f6f0ff;
    color: #73589b;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 5px 10px;
  }

  .article-duration {
    position: absolute;
    top: 4%;
    right: 4%;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.66);
    backdrop-filter: blur(41.54999923706055px);
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 8px 10px;
  }

  .video-duration {
    position: absolute;
    top: 4%;
    right: 4%;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.66);
    backdrop-filter: blur(41.54999923706055px);
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 8px 10px;
  }

  .video-playBtn {
    position: absolute;
    top: 29%;
    left: 45%;
  }

  img {
    height: 205px;
  }
}

.similarVideos {
  h5 {
    color: #000;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.drawerContainer {
  .rightSide-createContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 19.2px;
    background: #fff;
    // margin: 0px 20px 20px;
    padding: 20px;

    h5 {
      color: #0f1419;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.28px;
      margin: 0;
    }

    .create {
      display: flex;
      align-items: center;
      padding: 10px;
      gap: 10px;
      cursor: pointer;

      color: #323232;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 23.4px */
    }

    .create-active {
      display: flex;
      align-items: center;
      padding: 10px;
      gap: 10px;
      cursor: pointer;

      background: linear-gradient(
        90deg,
        rgba(179, 153, 253, 0.34) 0%,
        rgba(237, 223, 255, 0) 100%
      );
      color: #73589b;
      h6 {
        color: #73589b;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 23.4px */
      }
    }
  }
}

.viewComments {
  .modal-content {
    background: #fff;
    border-radius: 24px;

    .modal-header {
      border-bottom: none !important;

      button {
        background-color: rgba(0, 0, 0, 0.1) !important;
        border-radius: 50%;
      }
    }

    .commentDiv {
      border-bottom: 2px solid #eeeeee;

      h3 {
        color: #323232;
        font-feature-settings: "clig" off, "liga" off;
        font-family: "Roboto", sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      p {
        color: #444;
        font-feature-settings: "clig" off, "liga" off;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: #f8f4ff !important;
}

.join-Btn:hover {
  background-color: var(--primary-color) !important;
}

.Share-PopUp {
  .modal-content {
    background-color: #fff;
    border-radius: 20px;
    width: 100%;
  }

  .modal-body {
    .linkContainer {
      button {
        height: 56px;
        background-color: var(--primary-color);
        border: none;
      }
    }

    .horizontal--line {
      hr {
        border-bottom: 2px solid #b0b0b0;
        width: 100%;
      }

      p {
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        white-space: nowrap;
      }
    }

    .shareButtonContainer {
      button {
        display: flex;
        align-items: center;

        background-color: #ececec;
        border: none;
      }
    }
  }
}

.ProfileDetail--section {
  width: 100%;
  padding: 5%;
  height: max-content;
  overflow-x: hidden;
  position: relative;

  .module-bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;

    img {
      height: 100%;
      width: 100%;
      object-fit: none;
    }
  }

  .module-container {
    position: relative;
    width: 100%;
    height: max-content;
    border-radius: 1.25rem;
    border-radius: 1.25rem;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
    // padding: 2.5rem;

    .profileImgContainer {
      border: 4px solid #f2f2f2;
      padding: 5px;
      border-radius: 50%;
      width: fit-content;

      img {
        height: 200px;
        width: 200px;
        border-radius: 50%;
      }
    }
    .profileDetail {
      h6 {
        color: #323232;
        font-family: Inter;
        font-size: 42px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.84px;
      }

      button {
        background: var(--primary-color);
      }
      .reportBtn {
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        background: #fff !important;
      }
    }
    h3 {
      color: var(--primary-color);
      font-family: "Roboto", sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 36.4px */
    }

    .memberDiv {
      border-radius: 4px;
      border: 1px solid rgba(163, 162, 164, 0.34);
      color: #808080;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 5px 10px;
    }

    .categoryDiv {
      border-radius: 24px;
      background: #f6f0ff;
      padding: 7px 13px;
      width: fit-content;
      margin-bottom: 10px;

      color: #73589b;
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .containedBtn {
      border-radius: 5.6px;
      background: var(--primary-color);
      color: #fff;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .outlinedBtn {
      border-radius: 5.6px;
      border: 1.027px solid var(--primary-color);
      color: var(--primary-color);
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    p {
      color: #323232;
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 26px */
    }

    .suggested-clubs {
      border-radius: 17.28px;
      border: 1.08px solid #eee;
      background: #fff;
      height: max-content;

      h3 {
        color: #0f1419;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 15px 15px;
      }

      .clubs-card {
        p {
          color: #0f1419;
          font-family: "Roboto", sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        button {
          border-radius: 10798.921px;
          background: var(--primary-color);
          color: #fff;
          text-align: center;
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 20.52px; /* 128.25% */
        }
      }
    }
  }

  .tabs-section {
    .tabs-container {
      border-radius: 64.8px;
      border: 0.88px solid var(--primary-color);
      color: var(--primary-color);
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 10px 15px;
      cursor: pointer;
    }
    .tabs-container-active {
      border-radius: 64.8px;
      background: var(--primary-color);
      color: #fff;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 10px 15px;
      cursor: pointer;
    }
  }
}
