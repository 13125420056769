.modal-content {
  font-size: 10px;
  border-bottom: none;
  border-radius: 10px !important;
}

@media (min-width: 768px) {
  .modal-content {
    font-size: 16px;
    border-bottom: none;
    border-radius: 10px !important;
  }
}

.custom-modal1 {
  width: 278px;
  height: 205px;
}

@media (min-width: 768px) {
  .custom-modal1 {
    width: 400px;
    height: 355px;
  }
}

.fs {
  font-size: 16px;
}

@media (min-width: 768px) {
  .fs {
    font-size: 24px;
  }
}

.btn-primary-custom {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8.7253px 14.5422px;
  gap: 5.82px;
  width: 107px;
  height: 35.45px;
  background: #fd6f1f;
  border: 0.727109px solid #fd6f1f;
  border-radius: 36.3554px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13.088px;
  line-height: 17px;
  color: #ffffff;
}

.btn-primary-custom.custom-margin {
  margin-left: 70px;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .btn-primary-custom.custom-margin {
    margin-left: 130px;
    margin-top: 10px;
  }
}

.btn.btn-primary-custom {
  background: #fd6f1f;
  color: #ffffff;
}

.btn-primary-submit {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12.8025px 21.3376px;
  gap: 5.82px;
  width: 107px;
  height: 35.45px;
  background: #fd6f1f;
  border: 0.727109px solid #fd6f1f;
  border-radius: 36.3554px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13.088px;
  line-height: 17px;
  color: #ffffff;
}

@media (min-width: 768px) {
  .btn-primary-submit {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12.8025px 21.3376px;
    gap: 8.54px;
    width: 249px;
    height: 51.61px;
    background: #fd6f1f;
    border: 1.06688px solid #fd6f1f;
    border-radius: 53.3439px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 19.2038px;
    line-height: 26px;
    color: #ffffff;
  }
}

.custom-label {
  width: 140px;
  height: 9px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #696969;
  flex: none;
  order: 1;
  flex-grow: 0;
}

@media (min-width: 768px) {
  .custom-label {
    width: 187px;
    height: 22px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #696969;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}

.btn-primary-submit.submit-margin {
  margin-left: 70px;
  margin-top: 20px;
  margin-bottom: 22px;
}

@media (min-width: 768px) {
  .btn-primary-submit.submit-margin {
    margin-left: 90px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

/* .btn.btn-primary-submit:hover {
  background: #fd6f1f;
  color: #ffffff;
} */ 

.btn.btn-primary-submit{
  background: var(--primary-color);
  opacity: 0.9;
  color: #fff;
  border: none;
}

.btn.btn-primary-submit:hover{
  background: var(--primary-color); 
  color: #fff;
}

.top {
  margin-top: 200px;
  margin-left: 40px;
}

@media (min-width: 768px) {
  .top {
    margin-top: 20px;
  }
}

.small-header {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #6b7280;
}

@media (min-width: 768px) {
  .small-header {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 19.2px;
    line-height: 24px;
    text-align: center;
    color: #6b7280;
  }
}

.question-text {
  width: 162px;
  height: 12px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  font-size: 8.94374px;
  line-height: 130%;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  .question-text {
    margin-left: 30px;
    width: 289px;
    height: 21px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

.question-container {
  margin-left: 15px;
}
@media (min-width: 768px) {
  .question-container {
    margin-left: 35px;
  }
}

.option-container {
  margin-bottom: 0px;
}

@media (min-width: 768px) {
  .option-container {
    margin-left: 30px;
    margin-top: 5px;
  }
}

.font-text {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #4e4e4e;
  margin-left: 4px;
}

@media (min-width: 768px) {
  .font-text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #4e4e4e;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

.custom-modal {
  width: 289px;
  height: 396px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-modal > .modal-content{
  border-radius: 10px;
}

@media (min-width: 768px) {
  .custom-modal {
    width: 480px;
    height: 639px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 30px !important;
  }
}

.selected-radio {
  background-color: #027a48; /* Desired background color */
}

.step1text {
  width: 233.52px;
  height: 38px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #1f2937;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

@media (min-width: 768px) {
  .step1text {
    width: 363.2px;
    height: 65px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    line-height: 32px;

    color: #1f2937;
  }
}

.ellipse {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 9px;
  height: 9px;
  border: 1.11797px solid #9d9fac;
  border-radius: 50%;
  outline: none;
  left: 0px;
  top: 2px;
  margin-right: 2px;
}

.ellipse:checked {
  background-color: #027a48;
}

@media (min-width: 768px) {
  .ellipse {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 15px;
    height: 15px;
    border: 2px solid #9d9fac;
    border-radius: 50%;
    outline: none;
    left: 0px;
    top: 2px;
    margin-right: 5px;
  }
}

.form-check-input {
  width: 15px;
  height: 15px;
}

@media (min-width: 768px) {
  .form-check-input {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}

.modal1 {
  margin-top: 140px;
  margin-left: 30px;
}

/* .modal2 {
  margin-left: 35px;
} */

.messagesArea {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 75%;
}

.messagesArea div {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%; /* 20.303px */
  letter-spacing: 0.21px;
  border-radius: 10px;
  background: rgba(115, 88, 155, 0.0901960784);
  padding: 10px;
  /* margin-left: auto; */
}