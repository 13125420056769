.ExpertDashboard {
  max-height: calc(100vh - 75px);
  height: calc(100vh - 75px);
  overflow: hidden;
  .ExpertDashboard--Col {
    max-height: calc(100vh - 61px);
    height: 100%;
    padding-bottom: 30px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px; /* Width of the scrollbar */
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d7d7d7; /* Color of the scrollbar handle */
      border-radius: 4px; /* Border radius of the scrollbar handle */
    }
  }

  // .CalendarWidget--Content {
  //   .Appointments--Container {
  //     .Appointment--Item {

  //     }
  //   }
  // }
}
