
.labels{
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight:  600;
  font-size: 12px;
  line-height: 130%;
  color: #383838;
}

.custom-font1 {  
width: 170px;
height: 21px;
font-family: "Roboto", sans-serif;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 130%;
color: #4E4E4E;
white-space: nowrap;
margin-bottom: 10px;


}
.custom-dialog-content {
  width: 90vw; /* Set the desired width */
  max-height: 80vh; /* Set the desired max height */
}

.customLabel {
width: 236px;
height: 26px;
font-family: "Roboto", sans-serif;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 130%;
color: #383838;
white-space: nowrap;
}

.custom-border-checkbox .custom-control-input:checked ~ .custom-control-label::before {
border: 2px solid #9D9FAC;
}

