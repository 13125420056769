.footer--container {
  padding: 0 5%;
}
.footer-Col-Container h6 {
  color: #73589b;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.footer-Col-Container p {
  color: #272829 !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 25px !important;
  font-family: "Roboto", Sans-serif;
}

.footer-rights-text {
  color: #000 !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important; /* 150% */
}

.footer--email-input {
  border-radius: 50px !important;
  border: 1px solid #73589b !important;
}

.form-control:focus{
  box-shadow: 0 0 0 0.25rem #73589b50 !important;
}