.OrderPreview--Skeleton {
    & > div {
        @media only screen and (max-width: 500px) {
            flex-direction: column;
            height: 100% !important;
        }
        .OrderPreview--Skeleton__Left {
            flex: 1;
            & > div {
                flex: 1;
            }
        }
        .OrderPreview--Skeleton__Right {
            flex: 1;
        }
    }
}
