.related-course-section {
  width: 100%;
  /*  margin: 2rem;
  height: 80vh; */
}

.related-course-section .swiper {
  width: 100%;
  height: 100%;
}
/* .relatedCourse--Swiper {
  padding: 0 20px;
} */

.relatedCourse--Swiper .swiper-wrapper{
  max-width: 1440px;
  margin: auto;
}

.related-course-section .relatedCourse--SwiperItem {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: 410px;
  width: 280px !important;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.SwiperItem--Card {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0 20px 0 0;
}
.SwiperItem--Card__Image {
  position: relative;
}
.SwiperItem--Card__Image button {
  border: none;
  background: var(--button-primary-color);
  color: #fff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 7px 0;
}
.SwiperItem--Card__Image div span {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.relatedCourse--SwiperItem:first-child {
  margin-left: 20px;
}

.SwiperItem--Card .course-card-title {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  margin-top: 10px;
}
.SwiperItem--Card .course-card-title span {
  color: #73589b;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
}
.SwiperItem--Card .course-card-title p {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.related-course-section .relatedCourse--SwiperItem img {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 12px;
}

.related-course-section .swiper-slide {
  width: 80%;
}

.related-course-section .swiper-slide:nth-child(2n) {
  width: 60%;
}

.related-course-section .swiper-slide:nth-child(3n) {
  width: 40%;
}

.related-course-container {
  width: 100%;
  padding: 1rem;
}

.related-course-heading {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3rem;
  text-align: center;
}

.related-course-desc {
  padding: 2rem 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.1rem;
  text-align: center;
}

.related-course-cards {
  width: 100%;
  /* height: 35rem; */
}

.related-course-card {
  display: flex;
  padding-right: 1rem;
}

/* .arrows {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 4.5rem;
  position: absolute;
  top: 70%;
  cursor: pointer;
} */

.course-card-container {
  width: 20rem;
  height: 25rem;
  border-radius: 1rem;
  border: 2px solid #ebebeb;
  padding: 1rem;
  box-shadow: 0px 0px 10px 0px #00000033;
  margin: auto;
}

.course-card-title {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  width: 18.1875rem;
  height: 3rem;
  min-height: 3rem;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #101828;
}

.course-details {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
}

.course-selfPaced {
  width: 8rem;
  height: 2.5rem;
  border-radius: 1.1rem;
  padding: 0.3rem;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  background: #9fd68b1a;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.05rem;
  line-height: 1.1rem;
  text-align: center;
  color: #4c9234;
}

.course-buttons {
  display: flex;
  gap: 0.2rem;
}

.course-card-btn {
  width: 18rem;
  height: 3rem;
  border-radius: 54px;
  padding: 0.7rem 3rem;
  background-color: #fd6f1f;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: white;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
}
.swiper-button-next,
.swiper-button-prev {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  color: rgb(115, 88, 155);
  position: absolute;
  top: 50%;
  cursor: pointer;
}

/* .swiper-button-next {
  margin-left: 4rem;
}

.swiper-button-prev {
  margin-left: 0rem;
} */

.custom-arrow {
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  color: rgb(115, 88, 155);
  /* color: white; */
  font-weight: 500;
  border-radius: 100%;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.relatedCourse--Swiper .swiper-button-prev::after {
  content: url(/public/assets/swiper/chevron-left.png) !important;
  background: #73589b;
  aspect-ratio: 1/1;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.relatedCourse--Swiper .swiper-button-next::after {
  content: url(/public/assets/swiper/chevron-right.png) !important;
  background: #73589b;
  aspect-ratio: 1/1;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.relatedCourse--Swiper .swiper-button-next,
.relatedCourse--Swiper .swiper-button-prev {
  width: max-content;
}

@media (max-width: 500px) {
  .related-course-section .relatedCourse--SwiperItem {
    height: 350px;
  }
  .relatedCourse--Swiper .swiper-button-next,
  .relatedCourse--Swiper .swiper-button-prev {
    top: 90% !important;
  }
  .related-course-section .relatedCourse--SwiperItem {
    width: 200px !important;
  }
  .SwiperItem--Card .course-card-title span {
    font-size: 10px;
  }
  .SwiperItem--Card .course-card-title p {
    font-size: 12px;
  }
  .module-section .swiper-button-next,
  .module-section .swiper-button-prev {
    top: 98% !important;
  }
  .module-section .swiper-button-next {
    margin-right: 30px;
  }
  .module-section .swiper-button-prev::after {
    content: url(/public/assets/swiper/left.png) !important;
  }
  .module-section .swiper-button-next::after {
    content: url(/public/assets/swiper/right.png) !important;
  }

  .related-course-container {
    width: 100%;
    padding: 0;
    position: relative;
    margin: 0;
  }
  .related-course-heading {
    font-size: 1.8rem;
    line-height: 3rem;
    text-align: center;
    width: 100%;
    margin-left: 0;
  }
  .related-course-desc {
    margin-left: 0;
    padding: 1.5rem;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
  }
  .related-course-cards {
    width: 100%;
    margin-left: 0;
  }
  .related-course-cards .swiper-button-next,
  .related-course-cards .swiper-button-prev {
    display: none !important;
  }
  .related-course-card {
    display: flex;
    margin: 2rem;
  }
  .course-card {
    padding-bottom: 40px;
  }
  .course-card-container {
    width: 20rem;
    padding: 0;
    height: max-content !important;
    margin-left: 10%;
    border-radius: 1rem;
    border: none;
    /* padding: 1rem; */
    box-shadow: none;
  }
  .course-card-image {
    width: 100%;
  }
  .course-card-image img {
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 12px;
  }
  .course-card-title {
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin-top: 10px;
    height: fit-content;
    min-height: fit-content;
  }
  .course-details {
    margin-top: 0.5rem;
    padding: 0.5rem 0;
  }
  .course-card-btn {
    width: 80%;
    height: 3.5rem;
    border-radius: 54px;
    padding: 0.7rem 1rem;
    background-color: #fd6f1f;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.25rem;
    color: white;
    justify-items: center;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    margin-left: 2rem;
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    color: rgb(115, 88, 155);
    position: absolute;
    top: 50%;
    cursor: pointer;
  }
  .swiper-button-next {
    margin-left: 1rem;
  }
  .swiper-button-prev {
    margin-left: 1rem;
  }
  .custom-arrow {
    width: 20px; /* Adjust the width as needed */
    height: 20px; /* Adjust the height as needed */
    color: rgb(115, 88, 155);
    /* color: white; */
    font-weight: 500;
    border-radius: 100%;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  .related-course-section {
    width: 100%;
    margin: 2rem 0;
    height: 100%;
  }
}
.related-course-section .swiper-button-next:after {
  margin-left: auto;
  margin-right: 1rem;
}
