.SessionDashboard--StatsCards {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 10px;
  .StatsCard--Item {
    padding: 10px;
    background-color: rgba(115, 88, 155, 0.1);
    border-radius: 8px;
    h6 {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 23.4px */
    }
    .Item--Timings {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 10px;
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 130%; /* 18.2px */
        }
      }
      .Item--Tag {
        padding: 2px 6px;
        border-radius: 10px;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }
      .Expert {
        background-color: #6c46a0;
        color: #fff;
      }

      .Client {
        background-color: #fff;
        color: #6c46a0;
        border: 0.5px solid #6c46a0;
      }
      .OnTime {
        background-color: #55a51d;
        color: #fff;
      }
      .Late {
        background-color: #df2a2a;
        color: #fff;
      }
    }
  }
}

.SessionDashboard--GraphCards {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 10px;
  .GraphCard--Item {
    padding: 10px;
    background-color: rgba(115, 88, 155, 0.1);
    border-radius: 8px;
    h6 {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 23.4px */
    }
    .Item--GraphContainer {
      height: 50px;
      &:nth-child(1) {
        background-color: rgba(108, 70, 160, 1);
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &:nth-child(2) {
        background-color: rgba(108, 70, 160, 0.85);
      }
      &:nth-child(3) {
        background-color: rgba(108, 70, 160, 0.75);
      }
      &:nth-child(4) {
        background-color: rgba(108, 70, 160, 0.65);
      }
      &:nth-child(5) {
        background-color: rgba(108, 70, 160, 0.5);
      }
      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      p {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 19.2px */
      }
      span {
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
      }
    }
    .Lang--Item {
      color: #fff;
      height: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:nth-child(1) {
        background-color: #55a51d;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &:nth-child(2) {
        background-color: #5f8cff;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      p {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 19.2px */
      }
      span {
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
      }
    }
  }
}
.SessionDashboard--TabsContainer {
  border-bottom: 1px solid #d9d9d9;
  //box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.37);

  .SessionDashboard--Tabs {
    .MuiTabs-flexContainer {
      button {
        text-transform: capitalize;
        padding: 12px 10px !important;
        font-weight: 600 !important;
      }
    }
    .Mui-selected {
      color: #73589b;
    }
    .css-1aquho2-MuiTabs-indicator {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      height: 4px !important;
      background-color: #73589b;
    }
  }
}
.SessionDashboard--TabItemsContainer {
  .SessionTab--Panel {
    padding: 20px 10px;
  }
}

.AutoComplete--Container {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  padding: 0 10px;
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .MuiAutocomplete-endAdornment {
    button {
      display: none;
    }
  }
  & > button {
    border: none;
    border-left: 1px solid #b9b4b4;
    padding: 10px;
    background-color: transparent;
  }
  svg {
    color: #6f6b6b;
  }
  .MuiFormLabel-root {
    display: none;
  }
}
.SessionPrescription--Table,
.SessionLabAdvice--Table {
  width: 100%;
  padding: 10px;
  tr {
    & > * + * {
      margin-left: 0.5rem; /* 8px */
    }
  }
  .Table--Head {
    div {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 18.2px */
    }
  }
  .Table--Head,
  .Table--Body {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .Table-span-1 {
    grid-column: span 1 / span 1;
  }
  .Table-span-2 {
    grid-column: span 2 / span 2;
  }
  .Table-span-3 {
    grid-column: span 3 / span 3;
  }
  .Table-span-4 {
    grid-column: span 4 / span 4;
  }
  .Table-span-5 {
    grid-column: span 5 / span 5;
  }
  .Table-span-6 {
    grid-column: span 6 / span 6;
  }
}

.PrescriptionOtherData--Container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  .OtherData--Item {
    & > div {
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 8px;
      margin-top: 10px;
      .rdw-editor-main {
        padding: 10px;
        max-height: 200px;
        min-height: 200px;
      }
    }
  }
}

.generated-prescription-container {
  background: rgba(255, 255, 255, 0.29);
  border-radius: 16px;
  //box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  //backdrop-filter: blur(10.2px);
  //-webkit-backdrop-filter: blur(10.2px);
  border: 1px solid rgba(255, 255, 255, 1);
}

.SessionPrescription--box{
  box-shadow: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}