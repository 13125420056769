@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.SelectedFace {
  border: 3px solid var(--primary-color) !important;
}

.fade-out {
  opacity: 0;
  animation: fadeOutAnimation 0.5s ease-out;
}

.showAssessment {
  border: none !important;
}

.Stella--AssessmentScore {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.Stella--AssessmentScore svg {
  width: 100px;
}
.Stella--AssessmentScore h3 {
  font-size: 2rem;
  text-align: center;
}
.Stella--AssessmentScore p {
  color: #252625;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 30.97px; /* 110.605% */
}
.Stella--AssessmentScore button {
  border-radius: 8px;
  background: #73589b;
  display: inline-flex;
  padding: 18px 53px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: "Roboto", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 23.221px; /* 105.548% */
}

.fade-in {
  opacity: 1;
  animation: fadeInAnimation 0.5s ease-in;
}

.min-h-screen {
  min-height: calc(100vh - 68px);
  position: relative;
}

.talk-to-stella-screen {
  padding: 50px;
  z-index: 999;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, rgba(185, 163, 248, 0.26) 0.13%, rgba(185, 163, 248, 0) 100%);
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen {
    margin-top: 0;
    width: 100%;
    height: 100%;
    padding: 0px;
    background: #fff;
  }
}
.talk-to-stella-screen .IntroSection {
  position: relative;
}
.talk-to-stella-screen .IntroSection.fade-out-entering, .talk-to-stella-screen .IntroSection.fade-out-exiting {
  opacity: 1;
}
.talk-to-stella-screen .IntroSection.fade-out-entered {
  opacity: 0;
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .IntroSection img {
    width: 60px;
    height: 60px;
  }
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .IntroSection {
    padding-top: 0 !important;
    padding-bottom: 10px !important;
    margin-top: 20px !important;
  }
}
.talk-to-stella-screen .IntroSection .pingContainer,
.talk-to-stella-screen .IntroSection .pingDot {
  height: 1.25rem !important;
  width: 1.25rem !important;
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .IntroSection .pingContainer {
    right: 5px;
  }
}
.talk-to-stella-screen h1 {
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 85px;
  font-style: normal;
  font-weight: 700;
  line-height: 96px; /* 112.941% */
  letter-spacing: -0.5px;
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen h1 {
    font-size: 35px;
    text-align: left;
    line-height: 1rem;
  }
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .FacesList--Container {
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 0 30px 0px 10px;
    width: 100vw;
    overflow-x: auto;
  }
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .FacesList--Container div {
    gap: 10px !important;
    margin-top: 0 !important;
  }
  .talk-to-stella-screen .FacesList--Container div button {
    aspect-ratio: 1/1;
    min-height: 55px;
    min-width: 55px;
  }
  .talk-to-stella-screen .FacesList--Container div button img {
    aspect-ratio: 1/1;
    height: 55px;
    width: 55px;
  }
}
.talk-to-stella-screen h6 {
  color: #413d45;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen h6 {
    font-size: 21px;
    min-width: 139px;
    text-align: left;
  }
}
.talk-to-stella-screen .pingContainer {
  position: relative;
  display: flex;
  height: 16px;
  width: 16px;
}
.talk-to-stella-screen .pingContainer .animate-ping {
  position: absolute;
  display: inline-flex;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: #97df8c;
  animation: ping 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
}
.talk-to-stella-screen .pingContainer .pingDot {
  position: relative;
  display: inline-flex;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  background-color: #57b049;
}
.talk-to-stella-screen .chat-box {
  padding: 3rem;
  max-width: 1280px;
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  box-shadow: 3px 20px 13px -1px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  position: relative;
  background: #fff;
  z-index: 10;
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .chat-box {
    width: 100%;
    height: -moz-max-content;
    height: max-content;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    border-radius: 0;
    box-shadow: none;
    gap: 0;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
    border-radius: 1.25rem;
    padding: 1rem;
  }
}
.talk-to-stella-screen .chat-box .chat-box--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  background-color: var(--primary-color);
  padding: 15px 20px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 84px;
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .chat-box .chat-box--header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.talk-to-stella-screen .chat-box .chat-box--header .StellaAvatar--Selector {
  position: relative;
}
.talk-to-stella-screen .chat-box .chat-box--header .StellaAvatar--Selector img {
  border: 1.545px solid #f7f7f7;
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .chat-box .chat-box--header .StellaAvatar--Selector img {
    width: 43px;
    height: 43px;
  }
}
.talk-to-stella-screen .chat-box .chat-box--header .StellaAvatar--Selector svg {
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.talk-to-stella-screen .chat-box .chat-box--header .LanguageSelector p {
  text-transform: capitalize;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 42.063px; /* 191.198% */
}
.talk-to-stella-screen .chat-box .chat-box--header .chat-box--logo {
  flex: 1;
}
.talk-to-stella-screen .chat-box .chat-box--header .chat-box--logo p {
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 369.231% */
  letter-spacing: -0.71px;
  text-align: center;
  margin-bottom: 0 !important;
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .chat-box .chat-box--header .chat-box--logo p {
    font-size: 1.5rem;
    text-align: left;
  }
}
.talk-to-stella-screen .chat-box .chat-box--header button {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  color: #fff;
}
.talk-to-stella-screen .chat-box .chat-box--header button > svg {
  width: 20px;
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .chat-box .chat-box--header button > svg {
    width: 25px;
  }
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .chat-box .chat-box--header .LanguageDropdown p,
  .talk-to-stella-screen .chat-box .chat-box--header .LanguageDropdown .DropDownIcon {
    display: none;
  }
}
.talk-to-stella-screen .chat-box .chat-box--content {
  padding: 24px 14px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 450px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-position-y: center;
  background-size: cover !important;
  background-blend-mode: hard-light;
  position: relative;
  padding-bottom: 70px;
  background-color: #fff;
  border: 1px solid #cecece;
  border-radius: 20px;
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .chat-box .chat-box--content {
    flex: 1;
    min-height: 500px;
    height: 100%;
    max-height: 500px;
  }
}
.talk-to-stella-screen .chat-box .chat-box--content .space-y-5 > * + * {
  margin-top: 1rem;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--buttonArea {
  padding: 10px;
  margin: -10px -10px 10px -10px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--buttonArea .chat-box--logo p {
  color: var(--primary-color);
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 42.063px; /* 175.265% */
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--buttonArea button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 8px;
  font-size: 14px;
  text-transform: capitalize;
  min-width: -moz-max-content;
  min-width: max-content;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--buttonArea button svg {
  width: 28px;
  height: 28px;
  color: var(--primary-color);
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--buttonArea button p {
  margin-bottom: 0 !important;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats {
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  height: 400px;
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats {
    padding-bottom: 0px;
    height: 400px;
  }
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats > * + * {
  margin-top: 1.2rem;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats::-webkit-scrollbar-thumb {
  background-color: #e1e1e1; /* Color of the scrollbar handle */
  border-radius: 4px; /* Border radius of the scrollbar handle */
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .response-area {
  display: flex;
  align-items: flex-start;
  flex-direction: revert;
  gap: 10px;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .avatar {
  position: relative;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .avatar img {
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center top;
     object-position: center top;
  aspect-ratio: 1/1;
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .avatar img {
    min-width: 30px;
    height: 30px;
  }
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .avatar span {
  width: 12px;
  height: 12px;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #22c55e;
  border-radius: 50%;
  border: 1px solid #fff;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .response--container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .response {
  padding: 12px 15px;
  border-radius: 20px;
  width: -moz-max-content;
  width: max-content;
  max-width: 589px;
  font-weight: 500;
  font-size: 16px;
  color: #0e0f0c;
  word-wrap: break-word;
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .response {
    max-width: 242px;
  }
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .server-response {
  background-color: #fff;
  border: 1.545px solid rgba(115, 88, 155, 0.3294117647);
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom-left-radius: 0;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .server-response .audio-player {
  /* Optional: Customize the color of the progress bar */
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .server-response .audio-player {
    width: 230px;
  }
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .server-response .audio-player::-webkit-media-controls-panel {
  background-color: #fff !important;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .server-response .audio-player::-webkit-media-controls-panel {
  background-color: var(--primary-color); /* Change the background color of the controls for WebKit browsers (e.g., Chrome, Safari) */
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .server-response .audio-player::-moz-media-controls-panel {
  background-color: var(--primary-color) !important; /* Change the background color of the controls for Mozilla Firefox */
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .server-response .audio-player::-ms-media-controls {
  background-color: var(--primary-color) !important; /* Change the background color of the controls for Microsoft Edge */
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .server-response .audio-player::-webkit-media-slider-container {
  background-color: var(--primary-color) !important; /* Change the background color of the progress bar for WebKit browsers (e.g., Chrome, Safari) */
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .server-response .audio-player::-moz-media-slider {
  background-color: var(--primary-color) !important; /* Change the background color of the progress bar for Mozilla Firefox */
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .server-response .audio-player::-ms-media-slider {
  background-color: var(--primary-color) !important; /* Change the background color of the progress bar for Microsoft Edge */
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .response-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .response-buttons .button--item {
  border-radius: 9px;
  padding: 6px 16px;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  color: var(--primary-color);
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .my-response {
  border: 1.457px solid var(--purple-stroke, rgba(84, 28, 245, 0.19));
  background: linear-gradient(281deg, rgba(84, 28, 245, 0.2) -10.49%, rgba(255, 255, 255, 0) 96.51%), radial-gradient(314.45% 139.15% at 3.59% 3.24%, rgba(255, 255, 255, 0.49) 0%, rgba(255, 255, 255, 0.07) 100%);
  color: var(--primary-color);
  margin-left: auto;
  border-bottom-right-radius: 0;
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .my-response .audio-player {
    width: 230px;
  }
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .my-response .audio-player::-webkit-media-controls-panel {
  background-color: transparent !important;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .contains--explore-more {
  display: flex;
  flex-direction: column;
  width: -moz-max-content;
  width: max-content;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .contains--explore-more .response {
  max-width: 100%;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--chats .contains--explore-more .explore-btn {
  border: 1px solid;
  background-color: #fff;
  border-radius: 20px;
  padding: 6px 12px;
  font-size: 0.8em;
  font-weight: 500;
  margin: 15px auto;
  width: -moz-max-content;
  width: max-content;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--input {
  display: flex;
  align-items: center;
  border-radius: 7px;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  padding: 10px;
  background: rgba(255, 255, 255, 0.282);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  -webkit-backdrop-filter: blur(23px);
          backdrop-filter: blur(23px);
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .chat-box .chat-box--content .chat-content--input {
    position: fixed;
    border-radius: 0;
    background: #f4f1ff;
  }
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--input > div {
  flex: 1;
  border: 1px solid lightgray;
  border-radius: 10px;
  background-color: #fff;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--input button {
  margin: 0;
  border: none;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  width: 102px;
  box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.17);
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .chat-box .chat-box--content .chat-content--input button {
    width: 86px;
  }
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--input button svg {
  width: 20px;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--input .send-input--button {
  background-color: var(--primary-color);
  min-width: -moz-fit-content;
  min-width: fit-content;
  width: -moz-max-content;
  width: max-content;
  margin-left: 8px;
  padding: 11px;
  box-shadow: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--input .send-input--button svg {
  width: 29px;
  color: #fff;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--input .send-input--stop,
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--input .send-input--mic {
  border-radius: 50%;
  background-color: transparent;
  color: #666;
  width: auto;
  aspect-ratio: 1/1;
  box-shadow: none;
  margin-left: 10px;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--input .send-input--stop svg,
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--input .send-input--mic svg {
  color: #666;
  width: 30px;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--input input {
  width: 100%;
  border: 0;
  padding: 13.5px;
  background: #fff;
  border-radius: 8px;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--emotions {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
}
.talk-to-stella-screen .chat-box .chat-box--content .chat-content--emotions .emotion-item {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  flex-basis: calc(25% - 12px); /* Set the initial width of each item to occupy 25% of the container */
  margin: 6px;
  text-align: center;
  background-color: var(--primary-color);
  padding: 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.85rem;
  min-height: 80px;
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .chat-box .chat-box--content .chat-content--emotions .emotion-item {
    flex-basis: calc(50% - 12px);
  }
}
@media only screen and (max-width: 600px) {
  .talk-to-stella-screen .chat-box .chat-box--content .chat-content--emotions .emotion-item:last-child {
    flex-grow: 1; /* Allow the last item to take up the remaining space */
  }
}
.talk-to-stella-screen .dropdown-toggle::after {
  display: none !important;
}
.talk-to-stella-screen .dropdown-toggle {
  display: none !important;
}
.talk-to-stella-screen .dropdown-menu.show {
  transform: translate(calc(-100% + 7px), 0px) !important;
  padding: 10px 5px;
  max-height: 355px;
  overflow-y: scroll;
}
.talk-to-stella-screen .dropdown-menu.show::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
  background-color: #fff;
}
.talk-to-stella-screen .dropdown-menu.show::-webkit-scrollbar-thumb {
  background-color: #e1e1e1; /* Color of the scrollbar handle */
  border-radius: 4px; /* Border radius of the scrollbar handle */
}
.talk-to-stella-screen .dropdown-menu.show p {
  font-weight: 500;
}
.talk-to-stella-screen .dropdown-menu.show a,
.talk-to-stella-screen .dropdown-menu.show p {
  margin-bottom: 0 !important;
  font-size: 0.85em !important;
  font-weight: 500;
  padding: 5px 10px !important;
}
.talk-to-stella-screen .dropdown-menu.show a:hover,
.talk-to-stella-screen .dropdown-menu.show p:hover {
  border-radius: 15px;
}
.talk-to-stella-screen .dropdown-menu.show .selected-language {
  background-color: #c4e5f9;
  border-radius: 15px;
}
.talk-to-stella-screen .spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 9px;
  margin: -13px auto;
  text-align: center;
}
.talk-to-stella-screen .spinner div {
  width: 9px;
  height: 9px;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  animation: bouncedelay 1.4s infinite ease-in-out;
}
.talk-to-stella-screen .spinner div:nth-child(1) {
  animation-delay: -0.32s;
}
.talk-to-stella-screen .spinner div:nth-child(2) {
  animation-delay: -0.16s;
}
@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.talk-to-stella-screen #loading-bubble {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 37.6px;
  min-width: 73px;
  border-radius: 10px;
  position: relative;
  background-color: #e3e3e4;
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}/*# sourceMappingURL=TalkToStella2.css.map */