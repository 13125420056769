.VideoCard--module {
  // height: 297px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 1.84px 3.68px 29.44px 0px rgba(0, 0, 0, 0.08);
  padding: 1px;

  h6 {
    color: #000;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    cursor: pointer;
  }

  .title-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // min-width: 200px; /* Adjust based on your layout */
  }

  p {
    color: #707070;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto", sans-serif;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .clubDiv {
    border-radius: 24px;
    background: #f6f0ff;
    // color: #73589b;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 5px 10px;
  }

  .article-duration {
    position: absolute;
    top: 4%;
    right: 4%;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.66);
    backdrop-filter: blur(41.54999923706055px);
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 8px 10px;
  }

  .video-duration {
    position: absolute;
    top: 4%;
    right: 4%;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.66);
    backdrop-filter: blur(41.54999923706055px);
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 8px 10px;
  }

  .video-playBtn {
    position: absolute;
    top: 29%;
    left: 45%;
  }

  img {
    // height: 180px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    aspect-ratio: 16/9;
  }
}

.Video--Popup {
  width: auto;
}

.videoModal--container {
  .boxContainer {
    position: "absolute";
    top: "50%";
    left: "50%";
    transform: "translate(-50%, -50%)";
    // maxWidth: 600;
    min-width: 600;
    min-height: 500;
    background-color: "#f5f1fe";
    box-shadow: 16;
    // p: 4;
    aspect-ratio: 16 / 9;
    iframe {
      width: 900px;
      height: 600px;
    }
  }
}

@media screen and (max-width: 500px) {
  .videoModal--container {
    .boxContainer {
      aspect-ratio: 16 / 9;
      min-width: fit-content;
      min-height: fit-content;

      iframe {
        width: 100vw;
        height: 100vh;
      }
    }
  }
}

.BlogCard--module {
  // height: 297px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 1.84px 3.68px 29.44px 0px rgba(0, 0, 0, 0.08);
  padding: 1px;
  cursor: pointer;

  h6 {
    color: #000;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
  }

  .title-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // min-width: 200px; /* Adjust based on your layout */
  }

  p {
    color: #707070;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto", sans-serif;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .clubDiv {
    border-radius: 24px;
    background: #f6f0ff;
    // color: #73589b;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 5px 10px;
  }

  .article-duration {
    position: absolute;
    top: 4%;
    right: 4%;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.66);
    backdrop-filter: blur(41.54999923706055px);
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 8px 10px;
  }

  .video-duration {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.66);
    backdrop-filter: blur(41.54999923706055px);
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 8px 10px;
  }

  .video-playBtn {
    position: absolute;
    top: 29%;
    left: 45%;
  }

  img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    aspect-ratio: 16/9;
  }
}

@media screen and (max-width: 500px) {
  .podcastModal--container {
    .boxContainer {
      aspect-ratio: 16 / 9;
      min-width: fit-content;
      min-height: fit-content;

      iframe {
        width: 100vw;
        height: 100vh;
      }
    }
  }
}