.NoDataAvailable{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  gap: 10px;
  margin: 0 auto;
  img{
    width:250px;
  }
  p{
    font-size: 20px;
    font-weight: 500;
  }
}
