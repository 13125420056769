.kareify--SignIn {
  //   height: 70vh;
  // background: linear-gradient(0deg, #000 -9.26%, rgba(0, 0, 0, 0) 93.82%);
  // padding: 100px 0;
  .close-btn {
    margin: 0px 50px 0px auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  h2 {
    color: #fff;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 17.354px;
    font-style: normal;
    font-weight: 600;
    line-height: 17.971px; /* 103.554% */
  }

  @media only screen and (max-width: 500px) {
    .SignIn--box {
      margin-right: 0px !important;
      border-radius: 12px;
      background: #fff;
      padding: 20px 0;

      h6 {
        color: #000 !important;
        text-align: center !important;
        font-family: Roboto !important;
        font-size: 20px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal !important;
        letter-spacing: 0.7px !important;
        margin-bottom: 0 !important;

        .diff-clr {
          color: var(--primary-color) !important;
          font-family: Roboto !important;
          font-size: 20px !important;
          font-style: normal !important;
          font-weight: 500 !important;
          line-height: normal !important;
        }
      }

      h3 {
        color: #000 !important;
        text-align: center !important;
        font-family: Roboto !important;
        font-size: 28px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal !important;
        margin-bottom: 0 !important;
      }

      p {
        color: #585858 !important;
        text-align: center !important;
        font-family: Roboto !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
        margin-bottom: 0 !important;

        .diff-clr {
          color: var(--primary-color) !important;
          font-family: Roboto !important;
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 600 !important;
          line-height: normal !important;
          text-decoration-line: underline !important;
        }
      }
    }

    .kareify--SignIn h2 {
      margin-right: 0px !important;
    }
  }
  .SignIn--box {
    // border-radius: 20px;
    // background: rgba(255, 255, 255, 0.75);
    // padding: 60px 0 30px;
    // margin-right: 90px;
    // .login--with-social {
    // }

    input {
      border-radius: 9px !important;
      border: 1px solid #cecece !important;
      background: #fff !important;
      height: 56px !important;
      padding: 0 15px !important;
    }

    input::placeholder {
      color: #989898;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    h6 {
      color: #fff;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 1.68px;
      margin-bottom: 0;
    }

    // span {
    //   color: var(--primary-color);
    //   font-family: "Roboto", sans-serif;
    //   font-size: 36px;
    //   font-style: normal;
    //   font-weight: 700;
    //   line-height: normal;
    //   letter-spacing: 1.68px;
    // }

    h3 {
      color: #fff;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 26px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .horizontal--line {
      hr {
        border-bottom: 2px solid #b0b0b0;
        width: 100%;
      }

      p {
        color: #fff;
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    p {
      color: #fff;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      span {
        color: #fff;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    button {
      border-radius: 10px;
      background: var(--primary-color);
      /* Shadow/xs */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      padding: 10px;
      color: #fff;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 133.333% */
      letter-spacing: 0.018px;
      padding: 16px 25px;
      width: 75%;
    }

    button:disabled {
      background: #bcafcf !important;
      opacity: 1 !important;
    }

    button:active {
      background: var(--primary-color);
    }

    button:active:focus {
      box-shadow: none;
    }

    .MuiInputBase-input {
      background: #fff;
      border-radius: 10px !important;
    }
  }
}

.details--popup {
  .modal-content {
    border-radius: 20px;
    background: #fff;
    padding: 25px 20px;

    h5 {
      color: #000;
      font-family: "Roboto", sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
    }

    .input-section {
      margin: 20px 5px 0;
    }

    button {
      background: var(--primary-color);
      border: 0;
      color: #fff;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 133.333% */
      letter-spacing: 0.018px;
    }
  }
}
