.community--main .css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
  background-color: var(--primary-color) !important;
}
.community--main .feed--container .selection--tab {
  padding: 20px;
}
.community--main .feed--container .selection--tab .selectedTab {
  color: #73589b;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 20.8px */
  border-radius: 28px;
  background: #f7f4ff;
  cursor: pointer;
  padding: 5px 9px;
}
.community--main .feed--container .selection--tab .nonSelected {
  color: #343336;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 20.8px */
  border-radius: 28px;
  background: #f7f7f7;
  cursor: pointer;
  padding: 5px 9px;
}
@media screen and (max-width: 500px) {
  .community--main .feed--container .feedDiv {
    overflow-x: scroll;
  }
  .community--main .feed--container .feedDiv img {
    width: 56px !important;
  }
}
.community--main .feed--container .feedDiv img {
  width: 69px;
}
.community--main .feed--container .feed-container p {
  color: #73589b;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 14.606px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}
.community--main .feed--container hr {
  border-top: 2px solid #ececec;
  opacity: 1 !important;
  width: 75%;
  display: block;
  margin: 0;
}
.community--main .post--rightSide .rightSide-createContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 19.2px;
  border: 1.2px solid #eee;
  background: #fff;
  margin: 0px 20px 20px;
}
.community--main .post--rightSide .rightSide-createContainer h5 {
  color: #0f1419;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  margin: 0;
  padding: 20px;
}
.community--main .post--rightSide .rightSide-createContainer .create {
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 10px;
  cursor: pointer;
  color: #323232;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.community--main .post--rightSide .rightSide-createContainer .create-active {
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 10px;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(179, 153, 253, 0.34) 0%, rgba(237, 223, 255, 0) 100%);
  color: #73589b;
}
.community--main .post--rightSide .rightSide-createContainer .create-active h6 {
  color: #73589b;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.community--main .post--rightSide .rightSide-followContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 19.2px;
  border: 1.2px solid #eee;
  background: #fff;
  margin: 0px 20px 20px;
  padding: 20px 10px;
}
.community--main .post--rightSide .rightSide-followContainer hr {
  width: 100%;
}
.community--main .post--rightSide .rightSide-followContainer h5 {
  color: #0f1419;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  margin: 0;
}
.community--main .post--rightSide .rightSide-followContainer .followCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.community--main .post--rightSide .rightSide-followContainer .followCard h6 {
  color: #0f1419;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.community--main .post--rightSide .rightSide-followContainer .followCard p {
  color: var(--Dark-5, #5b7083);
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.306px;
}
.community--main .post--rightSide .rightSide-followContainer .followCard .followingBtn {
  border-radius: 10798.921px;
  background: var(--primary-color);
  border: 0;
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.52px;
  padding: 10px;
}
.community--main .post--rightSide .rightSide-followContainer .followCard .followBtn {
  border-radius: 10798.921px;
  border: 1.08px solid var(--primary-color);
  color: var(--primary-color);
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.52px;
  padding: 10px;
  background: #fff;
}

.post--container {
  display: block;
  border-top: 2px solid #ececec;
}
.post--container .post--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 500px) {
  .post--container .post--header .headerLeft img {
    height: 37px !important;
    width: 37px !important;
  }
  .post--container .post--header .headerLeft h3 {
    font-size: 15px !important;
  }
}
.post--container .post--header .headerLeft {
  display: flex;
  align-items: center;
  gap: 15px;
}
.post--container .post--header .headerLeft img {
  height: 56px;
  width: 56px;
  border-radius: 50%;
}
.post--container .post--header .headerLeft h3 {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 20.289px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
@media screen and (max-width: 500px) {
  .post--container .post--header .headerRight div {
    border-radius: 45.76px;
    background: #f6f0ff;
    padding: 8px 8px !important;
  }
  .post--container .post--header .headerRight p {
    font-size: 12px !important;
  }
}
.post--container .post--header .headerRight {
  display: flex;
  align-items: center;
  gap: 15px;
}
.post--container .post--header .headerRight div {
  border-radius: 45.76px;
  background: #f6f0ff;
  padding: 5px 15px;
}
.post--container .post--header .headerRight p {
  color: var(--primary-color);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 500px) {
  .post--container .postMedia div:first-child {
    width: 98vw !important;
  }
}
.post--container .postBody .postImage {
  height: 500px;
  -o-object-fit: contain;
     object-fit: contain;
}
.post--container .postBody .postMedia div:first-child {
  width: 640px;
}
.post--container .postBody .postBody-article {
  position: relative;
}
.post--container .postBody .postBody-article img {
  border-start-end-radius: 23px;
  border-start-start-radius: 23px;
}
.post--container .postBody .postBody-article h2 {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.post--container .postBody .postBody-article span {
  position: absolute;
  top: 4%;
  right: 4%;
  border-radius: 190.667px;
  background: rgba(0, 0, 0, 0.76);
  -webkit-backdrop-filter: blur(79.2219848633px);
          backdrop-filter: blur(79.2219848633px);
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px;
}
.post--container .postBody .postBody-audio {
  position: relative;
}
.post--container .postBody .postBody-audio img {
  border-radius: 23px;
}
.post--container .postBody .postBody-audio h2 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.572px;
}
.post--container .postBody .postBody-audio span {
  position: absolute;
  top: 4%;
  right: 4%;
  border-radius: 190.667px;
  background: rgba(0, 0, 0, 0.76);
  -webkit-backdrop-filter: blur(79.2219848633px);
          backdrop-filter: blur(79.2219848633px);
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px;
}
.post--container .postBody .postBody-video {
  position: relative;
}
.post--container .postBody .postBody-video img {
  border-start-end-radius: 23px;
  border-start-start-radius: 23px;
}
.post--container .postBody .postBody-video h2 {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.post--container .postBody .postBody-video svg {
  position: absolute;
  top: 34%;
  left: 44%;
}
.post--container .postBody .postBody-video span {
  position: absolute;
  top: 4%;
  right: 4%;
  border-radius: 190.667px;
  background: rgba(0, 0, 0, 0.76);
  -webkit-backdrop-filter: blur(79.2219848633px);
          backdrop-filter: blur(79.2219848633px);
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px;
}
.post--container .post--footer .post--footer-img {
  cursor: pointer;
}
.post--container .post--footer h5 {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.post--container .post--footer h6 {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.post--container .post--footer a {
  color: #6e6e6e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.post--container .post--footer .addComment-Container input::-moz-placeholder {
  color: #6e6e6e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 18.517px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.post--container .post--footer .addComment-Container input::placeholder {
  color: #6e6e6e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 18.517px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.post--container .post--footer p {
  color: #6e6e6e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 15.872px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.createModal .modal-content {
  background: #fff;
  border-radius: 28px;
}
.createModal .modal-content .createPost .MuiButton-outlined {
  border-radius: 9.6px !important;
  border: 1.2px solid #d9d9d9 !important;
  background: #fff !important;
  color: #989898;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none !important;
}
.createModal .modal-content .createPost .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #989898 !important;
  font-family: Roboto !important;
  font-size: 16pxpx !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.createModal .modal-content .createPost .postSubmit {
  border-radius: 8px;
  background: var(--primary-color);
  display: block;
  margin: 70px auto 20px;
}
.createModal .modal-content .createPost .captureImageDiv {
  position: relative;
}
.createModal .modal-content .createPost .captureImageDiv img {
  border: 1px solid #d9d9d9;
  height: 100px;
  width: 100px;
}
.createModal .modal-content .createPost .deleteFileBtn {
  background-color: #888;
  position: absolute;
  top: -12%;
  right: -12%;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  color: #fff;
  padding: 5px;
  z-index: 999;
}
.createModal .modal-content .createPost .stepTwo .selectedClub {
  position: relative;
}
.createModal .modal-content .createPost .stepTwo .selectedClub span {
  position: absolute;
  top: 5px;
  right: 5px;
}
.createModal .modal-content .createPost .stepTwo .clubContainer,
.createModal .modal-content .createPost .stepTwo .feedTextContainer {
  border-radius: 12px;
  border: 0.3px solid #d9d9d9;
  background: #fff;
  box-shadow: 0px 4.8px 16.8px 0px rgba(0, 0, 0, 0.16);
}
.createModal .modal-content .createPost .stepTwo .clubContainer {
  max-height: 520px;
  overflow-y: auto;
}
.createModal .modal-content .createPost .stepTwo .clubContainer .clubImage {
  height: 90px;
  width: 90px;
  border-radius: 50%;
}
.createModal .modal-content .createPost .stepTwo h6 {
  color: var(--primary-color);
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.createModal .modal-content .createVideoPost .MuiButton-outlined {
  border-radius: 9.6px !important;
  border: 1.2px solid #d9d9d9 !important;
  background: #fff !important;
  color: #989898;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.createModal .modal-content .createVideoPost .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #989898 !important;
  font-family: Roboto !important;
  font-size: 16pxpx !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.createModal .modal-content .createVideoPost .postSubmit {
  border-radius: 8px;
  background: var(--primary-color);
  display: block;
  margin: 70px auto 20px;
}
.createModal .modal-content .createBlog .MuiButton-outlined {
  border-radius: 9.6px !important;
  border: 1.2px solid #d9d9d9 !important;
  background: #fff !important;
  color: #989898;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.createModal .modal-content .createBlog .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #989898 !important;
  font-family: Roboto !important;
  font-size: 16pxpx !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.createModal .modal-content .createBlog .postSubmit {
  border-radius: 8px;
  background: var(--primary-color);
  display: block;
  margin: 70px auto 20px;
}
.createModal .modal-content .createPodcast .MuiButton-outlined {
  border-radius: 9.6px !important;
  border: 1.2px solid #d9d9d9 !important;
  background: #fff !important;
  color: #989898;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.createModal .modal-content .createPodcast .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #989898 !important;
  font-family: Roboto !important;
  font-size: 16pxpx !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.createModal .modal-content .createPodcast .postSubmit {
  border-radius: 8px;
  background: var(--primary-color);
  display: block;
  margin: 70px auto 20px;
}

@media screen and (max-width: 500px) {
  .categoryDetail-section {
    padding: 15% 0 !important;
  }
}
.categoryDetail-section {
  width: 100%;
  padding: 15% 5%;
  height: -moz-max-content;
  height: max-content;
  overflow-x: hidden;
  position: relative;
}
.categoryDetail-section .module-bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50vh;
}
.categoryDetail-section .module-bg-image img {
  height: 100%;
  width: 100%;
  -o-object-fit: none;
     object-fit: none;
}
.categoryDetail-section .module-container {
  position: relative;
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  border-radius: 1.25rem;
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
}
.categoryDetail-section .module-container h3 {
  color: var(--primary-color);
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.categoryDetail-section .module-container .memberDiv {
  border-radius: 4px;
  border: 1px solid rgba(163, 162, 164, 0.34);
  color: #808080;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 5px 10px;
}
.categoryDetail-section .module-container .categoryDiv {
  border-radius: 24px;
  background: #f6f0ff;
  padding: 7px 13px;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 10px;
  color: #73589b;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.categoryDetail-section .module-container .containedBtn {
  border-radius: 5.6px;
  background: var(--primary-color);
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.categoryDetail-section .module-container .outlinedBtn {
  border-radius: 5.6px;
  border: 1.027px solid var(--primary-color);
  color: var(--primary-color);
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.categoryDetail-section .module-container p {
  color: #323232;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.categoryDetail-section .module-container .suggested-clubs {
  border-radius: 17.28px;
  border: 1.08px solid #eee;
  background: #fff;
  height: -moz-max-content;
  height: max-content;
}
.categoryDetail-section .module-container .suggested-clubs h3 {
  color: #0f1419;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 15px 15px;
}
.categoryDetail-section .module-container .suggested-clubs .clubs-card p {
  color: #0f1419;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.categoryDetail-section .module-container .suggested-clubs .clubs-card button {
  border-radius: 10798.921px;
  background: var(--primary-color);
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.52px;
}
.categoryDetail-section .tabs-section .tabs-container {
  border-radius: 64.8px;
  border: 0.88px solid var(--primary-color);
  color: var(--primary-color);
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 10px 15px;
  cursor: pointer;
}
.categoryDetail-section .tabs-section .tabs-container-active {
  border-radius: 64.8px;
  background: var(--primary-color);
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 10px 15px;
  cursor: pointer;
}

.clubCardContainer .clubCard {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 5.6px 35px 0px rgba(0, 0, 0, 0.11);
  min-height: 420px;
}
.clubCardContainer .clubCard img {
  height: 240px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}
.clubCardContainer .clubCard .categoryName {
  position: absolute;
  top: 4%;
  left: 4%;
  border-radius: 24px;
  background: #f6f0ff;
  padding: 5px;
  color: #73589b;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.clubCardContainer h5 {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.clubCardContainer span {
  color: #707070;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}
.clubCardContainer p {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
}
.clubCardContainer button {
  border-radius: 16.8px;
  border: 1.4px solid #c5c5c5;
  background: var(--primary-color);
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 0;
}

.detailPage-Module h6 {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.detailPage-Module p {
  color: #707070;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.detailPage-Module .videoPlayer {
  margin-top: 2rem;
  width: 100% !important;
  height: auto;
  border-radius: 1rem;
}
.detailPage-Module .videoPlayervideo > div {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 16/9;
}
.detailPage-Module .videoPlayeraudio > div {
  width: 100% !important;
  aspect-ratio: 16/9;
}
.detailPage-Module .post--footer .post--footer-img {
  cursor: pointer;
}
.detailPage-Module .post--footer h5 {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.detailPage-Module .post--footer h6 {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.detailPage-Module .post--footer a {
  color: #6e6e6e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.detailPage-Module .post--footer .addComment-Container input::-moz-placeholder {
  color: #6e6e6e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 18.517px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.detailPage-Module .post--footer .addComment-Container input::placeholder {
  color: #6e6e6e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 18.517px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.detailPage-Module .post--footer p {
  color: #6e6e6e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 15.872px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 500px) {
  .detailPage-Module .contentClubDiv {
    padding: 5px !important;
  }
  .detailPage-Module .contentClubDiv span {
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 130% !important;
    color: #0e0f0c !important;
  }
  .detailPage-Module .contentActionDiv {
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 130% !important;
    color: #0e0f0c !important;
    padding: 5px !important;
  }
}
.detailPage-Module .contentContainer .contentActionDiv {
  border-radius: 78.55px;
  background: #ececec;
  color: #0e0f0c;
  font-family: "Roboto", sans-serif;
  font-size: 21.994px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 20px;
}
.detailPage-Module .contentContainer .contentClubDiv {
  border-radius: 50.27px;
  background: #f6f0ff;
  padding: 10px 20px;
}
.detailPage-Module .contentContainer .contentClubDiv span {
  color: var(--primary-color);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 20.946px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 500px) {
  .blog-LeftSide h2 {
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 140% !important;
  }
  .blog-LeftSide h6 {
    font-family: "Roboto" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 130% !important;
    color: #424242 !important;
  }
  .blog-LeftSide .profileImage {
    width: 32px !important;
    height: 32px !important;
  }
  .blog-LeftSide .postByDetail {
    padding: 5px !important;
  }
  .blog-LeftSide .postByDetail h6 {
    color: #000 !important;
    font-family: "Roboto" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 11.7629px !important;
    line-height: 14px !important;
    display: flex !important;
    align-items: center !important;
  }
  .blog-LeftSide .postByDetail p {
    color: #000;
    font-family: Roboto;
    font-size: 21.333px;
    font-style: italic;
    font-weight: 400;
    line-height: 36px; /* 168.75% */
    letter-spacing: -0.427px;
  }
}
.detailPage-Blogs .blog-LeftSide h2 {
  color: #2e2e2e;
  font-family: "Roboto", sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 76.8px; /* 120% */
}
.detailPage-Blogs .blog-LeftSide h6 {
  color: #424242;
  font-family: "Roboto", sans-serif;
  font-size: 21.333px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 27.733px */
}
.detailPage-Blogs .blog-LeftSide .profileImage {
  border-radius: 8px;
  height: 80px;
  width: 80px;
}
.detailPage-Blogs .blog-LeftSide .postByDetail {
  border-left: 2.667px solid #000;
  padding: 5px 20px;
}
.detailPage-Blogs .blog-LeftSide .postByDetail h6 {
  color: #000;
  font-family: Roboto;
  font-size: 21.333px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 168.75% */
}
.detailPage-Blogs .blog-LeftSide .postByDetail p {
  color: #000;
  font-family: Roboto;
  font-size: 21.333px;
  font-style: italic;
  font-weight: 400;
  line-height: 36px; /* 168.75% */
  letter-spacing: -0.427px;
}
.detailPage-Blogs .blog-RightSide h3 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 23.542px;
  font-style: normal;
  font-weight: 600;
  line-height: 33.333px; /* 141.593% */
  letter-spacing: 0.667px;
  text-transform: uppercase;
}
.detailPage-Blogs .blog-RightSide a {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33.333px; /* 138.889% */
  letter-spacing: 0.667px;
  text-decoration-line: underline;
  text-transform: uppercase;
}
.detailPage-Blogs .blog-relatedContainer {
  background: #f9f8ff;
  padding: 40px;
}
.detailPage-Blogs .blog-relatedContainer h3 {
  color: #150c06;
  text-align: center;
  font-family: Roboto;
  font-size: 65.885px;
  font-style: italic;
  font-weight: 500;
  line-height: 73.333px; /* 111.304% */
  letter-spacing: -2.667px;
}

.videoListing--module {
  min-height: 310px;
  display: flex;
  flex-direction: column;
}
.videoListing--module h6 {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.videoListing--module p {
  color: #707070;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.videoListing--module .clubDiv {
  border-radius: 24px;
  background: #f6f0ff;
  color: #73589b;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 10px;
}
.videoListing--module .article-duration {
  position: absolute;
  top: 4%;
  right: 4%;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.66);
  -webkit-backdrop-filter: blur(41.5499992371px);
          backdrop-filter: blur(41.5499992371px);
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 10px;
}
.videoListing--module .video-duration {
  position: absolute;
  top: 4%;
  right: 4%;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.66);
  -webkit-backdrop-filter: blur(41.5499992371px);
          backdrop-filter: blur(41.5499992371px);
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 10px;
}
.videoListing--module .video-playBtn {
  position: absolute;
  top: 29%;
  left: 45%;
}
.videoListing--module img {
  height: 205px;
}

.similarVideos h5 {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.drawerContainer .rightSide-createContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 19.2px;
  background: #fff;
  padding: 20px;
}
.drawerContainer .rightSide-createContainer h5 {
  color: #0f1419;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  margin: 0;
}
.drawerContainer .rightSide-createContainer .create {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  cursor: pointer;
  color: #323232;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
}
.drawerContainer .rightSide-createContainer .create-active {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(179, 153, 253, 0.34) 0%, rgba(237, 223, 255, 0) 100%);
  color: #73589b;
}
.drawerContainer .rightSide-createContainer .create-active h6 {
  color: #73589b;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
}

.viewComments .modal-content {
  background: #fff;
  border-radius: 24px;
}
.viewComments .modal-content .modal-header {
  border-bottom: none !important;
}
.viewComments .modal-content .modal-header button {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 50%;
}
.viewComments .modal-content .commentDiv {
  border-bottom: 2px solid #eeeeee;
}
.viewComments .modal-content .commentDiv h3 {
  color: #323232;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.viewComments .modal-content .commentDiv p {
  color: #444;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: #f8f4ff !important;
}

.join-Btn:hover {
  background-color: var(--primary-color) !important;
}

.Share-PopUp .modal-content {
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
}
.Share-PopUp .modal-body .linkContainer button {
  height: 56px;
  background-color: var(--primary-color);
  border: none;
}
.Share-PopUp .modal-body .horizontal--line hr {
  border-bottom: 2px solid #b0b0b0;
  width: 100%;
}
.Share-PopUp .modal-body .horizontal--line p {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
}
.Share-PopUp .modal-body .shareButtonContainer button {
  display: flex;
  align-items: center;
  background-color: #ececec;
  border: none;
}

.ProfileDetail--section {
  width: 100%;
  padding: 5%;
  height: -moz-max-content;
  height: max-content;
  overflow-x: hidden;
  position: relative;
}
.ProfileDetail--section .module-bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50vh;
}
.ProfileDetail--section .module-bg-image img {
  height: 100%;
  width: 100%;
  -o-object-fit: none;
     object-fit: none;
}
.ProfileDetail--section .module-container {
  position: relative;
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  border-radius: 1.25rem;
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
}
.ProfileDetail--section .module-container .profileImgContainer {
  border: 4px solid #f2f2f2;
  padding: 5px;
  border-radius: 50%;
  width: -moz-fit-content;
  width: fit-content;
}
.ProfileDetail--section .module-container .profileImgContainer img {
  height: 200px;
  width: 200px;
  border-radius: 50%;
}
.ProfileDetail--section .module-container .profileDetail h6 {
  color: #323232;
  font-family: Inter;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.84px;
}
.ProfileDetail--section .module-container .profileDetail button {
  background: var(--primary-color);
}
.ProfileDetail--section .module-container .profileDetail .reportBtn {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background: #fff !important;
}
.ProfileDetail--section .module-container h3 {
  color: var(--primary-color);
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 36.4px */
}
.ProfileDetail--section .module-container .memberDiv {
  border-radius: 4px;
  border: 1px solid rgba(163, 162, 164, 0.34);
  color: #808080;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 5px 10px;
}
.ProfileDetail--section .module-container .categoryDiv {
  border-radius: 24px;
  background: #f6f0ff;
  padding: 7px 13px;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 10px;
  color: #73589b;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ProfileDetail--section .module-container .containedBtn {
  border-radius: 5.6px;
  background: var(--primary-color);
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ProfileDetail--section .module-container .outlinedBtn {
  border-radius: 5.6px;
  border: 1.027px solid var(--primary-color);
  color: var(--primary-color);
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ProfileDetail--section .module-container p {
  color: #323232;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 26px */
}
.ProfileDetail--section .module-container .suggested-clubs {
  border-radius: 17.28px;
  border: 1.08px solid #eee;
  background: #fff;
  height: -moz-max-content;
  height: max-content;
}
.ProfileDetail--section .module-container .suggested-clubs h3 {
  color: #0f1419;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 15px 15px;
}
.ProfileDetail--section .module-container .suggested-clubs .clubs-card p {
  color: #0f1419;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ProfileDetail--section .module-container .suggested-clubs .clubs-card button {
  border-radius: 10798.921px;
  background: var(--primary-color);
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.52px; /* 128.25% */
}
.ProfileDetail--section .tabs-section .tabs-container {
  border-radius: 64.8px;
  border: 0.88px solid var(--primary-color);
  color: var(--primary-color);
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 10px 15px;
  cursor: pointer;
}
.ProfileDetail--section .tabs-section .tabs-container-active {
  border-radius: 64.8px;
  background: var(--primary-color);
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 10px 15px;
  cursor: pointer;
}/*# sourceMappingURL=Community.css.map */