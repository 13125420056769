/* mood bar */
.mood-section {
  margin-top: 2rem;
  width: 100%;
  height: 17rem;
  background-image: url("/public/assets/img/course_internal_journey/mood-monitor/mood-monitor-bg-img2.jpeg");
  background-size: contain;
  border-radius: 1rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.mood-section::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #73589b 0%, rgba(115, 88, 155, 0) 100%);
  border-radius: 17px;
}
.mood-section .MuiAlert-root {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translate(38%, -50%);
  width: 35%;
  background: #19cf30;
  color: #fff;
  z-index: 10;
}

.mood-heading {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.9rem;
  line-height: 2.1rem;
  text-align: center;
  color: #ffffff;
  position: relative;
  z-index: 1;
}

.mood-emojis {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.mood-emoji {
  display: flex;
  flex-direction: column;
  transition: transform 1s;
}

.mood-emoji:hover {
  transform: scale(1.3);
}

.emoji {
  width: 4.25rem;
  height: 4.25rem;
}

.emoji-text {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: #ffffff;
  text-align: center;
}

.saved-text {
  margin: 3rem 0 0 32rem;
  width: 6.875rem;
  height: 2.5rem;
  padding: 0.5rem;
  border-radius: 2.25rem;
  background: rgba(255, 255, 255, 0.24);
  backdrop-filter: blur(14.5px);
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 1.26594rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  animation: fade 2s ease-in-out; /* Adjust the animation duration as needed */
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

/* mood monitor */

.mood-monitor-section {
  overflow-x: hidden;
  min-height: 100vh;
  height: auto;
  position: relative;
  padding: 50px;
  width: 100%;
}

.mood-monitor-name {
  width: 100%;
  flex-shrink: 0;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.43919rem; /* 67.727% */
  /* margin: 1rem 0; */
  margin-bottom: 1rem;
  border-bottom: 0.0625rem solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.mood-monitor-bg-img {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.mood-monitor {
  width: 100%;
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
  padding: 3rem;
  position: relative;
}

.mood-analysis-heading {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  color: #100f11;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.mood-monitor-desc {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: 0.02813rem;
}

.mood-analysis-container {
  width: 100%;
  height: max-content;
  border-radius: 0.4375rem;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
}

.mood-analysis-item {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
  border-bottom: 0.0625rem solid #e7e7e7;
}

.mood-analysis-item:last-child {
  border-bottom: none;
}
/* .mood-line {
  width: 77.3125rem;
  height: 0.0625rem;
  background: linear-gradient(0deg, #e7e7e7 0%, #e7e7e7 100%);
} */

.mood-analysis-text {
  display: flex;
  gap: 1rem;
}

.mood-monitor-emoji {
  width: 1.86538rem;
  height: 1.86538rem;
  margin-top: -0.1rem;
}

.mood-dlt-icon,
.calendar-icon,
.info-icon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

/* .info-icon {
  margin-top: 0.5rem;
} */

.mood-monitor-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 2.5rem;
  cursor: pointer;
  padding: 0 1rem;
}

.modules-prev-text {
  color: #333;
  font-family: "Roboto", sans-serif;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: right;
}

.prev-arrow,
.next-arrow {
  min-width: 2.1875rem;
  min-height: 2.1875rem;
  width: 2.1875rem;
  height: 2.1875rem;
  margin-left: -0.1rem;
  border-radius: 100%;
  margin-top: -0.5rem;
  box-shadow: 0px 2.5px 8.125px 0px #00000030;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prev-arrow {
  transform: rotate("90");
}

.prev-btn,
.next-btn {
  display: flex;
  gap: 0.625rem;
}

.prev-btn {
  flex-direction: row-reverse;
}

@media (max-width: 500px) {
  .saved-text {
    display: none;
  }
  .mood-monitor-sidebar,
  .mood-monitor-bg-img {
    display: none;
  }
  .mood-monitor {
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    padding: 1rem;
    margin: 0;
  }
  .mood-section .MuiAlert-root {
    width: 95%;
    transform: translate(-50%, -50%);
    bottom: 8vh;
  }
  .mood-monitor-desc {
    font-size: 0.875rem;
    letter-spacing: 0.02188rem;
  }
  .mood-analysis-calendar {
    margin-left: -1rem;
    gap: 0.5rem;
  }
  .mood-analysis-container {
    width: 98%;
    height: 100%;
    border-radius: 0.4375rem;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
  }
  .mood-section {
    margin-top: 2rem;
    padding: 30px 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    margin: 2rem -20px 0;
    width: 110%;
  }

  .mood-heading {
    font-size: 1.5rem;
    /* margin-left: -8%; */
    width: 100%;
  }
  .mood-emojis {
    margin-top: 20px;
    position: relative;
    display: grid;
    gap: 20px 10px;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    padding: 0 30px;
    width: 100%;
  }
  .emoji-text {
    font-size: 0.8rem;
    line-height: 1.5rem;
    color: #ffffff;
    text-align: center;
  }
  .mood-emoji {
    flex: 1;
    align-items: center;
  }
  .emoji {
    width: 2.71244rem;
    height: 2.71244rem;
  }
  .mood-monitor-section {
    padding: 0;
  }

  /* .mood-monitor-name,
  .mood-monitor-desc,
  .mood-analysis-heading {
    padding: 0 1rem;
  } */

  /* .mood-monitor-section .sections-heading,
  .mood-monitor-section .sections-progress {
    padding: 0 1rem;
  } */
  /* .mood-monitor-container {
    padding: 2rem 0;
  } */
}
